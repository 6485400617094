import {i_container, i_step_options, i_stepper, i_stepperOptions} from "@/types/interfaces/dialog.interfaces";
import {ITEM} from "@/types/enums/dialog.enums";
import {Ref, ref} from "vue";
import {i_text} from "@/types/interfaces/structure.interfaces";
import {Base} from "@/Base";


class StepperBuilder extends Base {
    private stepper: i_stepper;

    constructor() {
        super(StepperBuilder.name);
        this.stepper = {
            type: ITEM.STEPPER,
            value: {
                currentStep: ref(0),
                steps: [],
                onSubmit: () => { this.log.warn("No submit event was given for this stepper.") }
            }
        }
    }

    public addStep(title: i_text, icon: string, content: i_container, criteria: Function, options?: i_step_options) {
        this.stepper.value.steps.push({
            name: title,
            icon: icon,
            content: content,
            completionCriteria: criteria,
            options: options
        });
        return this;
    }

    public setSubmit(callback: Function) {
        this.stepper.value.onSubmit = callback;
        return this;
    }

    public setOptions(options: i_stepperOptions) {
        this.stepper.value.options = options;
        return this;
    }

    public setStepRef(stepRef: Ref<number>) {
        this.stepper.value.currentStep = stepRef;
        return this;
    }

    public build() {
        return this.stepper;
    }
}

export const buildStepper = () => {
    return new StepperBuilder();
}
