import {ref} from "vue";
import {buildDialog} from "@/builder/dialog.builder";
import {buildContainer} from "@/builder/container.builder";
import {i_text} from "@/types/interfaces/structure.interfaces";
import {useDialog} from "@/services/dialog.service";

const DEF_ELEMENT_NAME = {
    en: "element",
    de: "das Element"
} as i_text;

export const removeDialog = (submitCallback: Function, options?: { submitText?: i_text, title?: i_text, elementName?: i_text }) => {
    const refLoading = ref(false);
    const dialog = buildDialog()
        .setTitle(options?.title ? options.title : {
            en: "Remove Element",
            de: "Element löschen"
        })
        .setColor("#f62e36")
        .setContent(
            buildContainer()
                .addHeader(options?.title ? options.title : {
                    en: "Remove Element",
                    de: "Element löschen"
                })
                .addDesc({
                    en: `Are you sure you want to delete the ${options?.elementName ? options.elementName.en : DEF_ELEMENT_NAME.en}? Any connected data will also be deleted.`,
                    de: `Bist du sicher, dass du ${options?.elementName ? options.elementName.de : DEF_ELEMENT_NAME.de} löschen möchtest. Alle damit verbundenen Daten werden ebenfalls gelöscht.`
                })
                .build()
        )
        .addAction(
            {
                en: "Abort",
                de: "Abbrechen"
            },
            () => useDialog().close()
        )
        .addAction(
            options?.submitText ? options.submitText : {
                en: "Delete",
                de: "Löschen"
            },
            () => {
                refLoading.value = true;
                submitCallback(refLoading);
            },
            {
                loading: refLoading
            }
        )
        .build();
    useDialog().set(dialog);
    useDialog().open();
}
