import "leaflet";
import "leaflet.markercluster";
import "leaflet/dist/leaflet.css";
import "leaflet/dist/leaflet";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import {Base} from "@/Base";
import {createApp} from "vue";
import {vuetify} from "@/vuetify.config";
import ZmMapLabel from "@/components/LeafletMap/zm-map-label.vue";
import {LID, MDI, SID} from "@/types/enums/frontend.enums";
import {Text} from "@/utils/text";
import {i_route, i_routeSegment} from "@/types/interfaces/routing.interfaces";
import {motColors, motIcons} from "@/types/lookups";
import {
    i_context,
    i_contextScreenData,
    i_label,
    i_mapObject,
    i_marker,
    i_poi,
    i_polyline,
    i_position
} from "@/types/interfaces/frontend.interfaces";
import {CONTEXT_TYPES, MAP_TEMPLATE} from "@/types/enums/general.enums";
import {useStore} from "@/services/store.service";
import {StringUtils} from "@/utils/string.utils";
import {useFetchWorker} from "@/services/fetch.worker.service";
import ZmMapobjectContext from "@/components/LeafletMap/zm-mapobject-context.vue";
import ZmMarkerIcon from "@/components/LeafletMap/zm-marker-icon.vue";
import {useGeocoder} from "@/services/geocoder.service";
import {usePolling} from "@/services/polling.service";

const L = window['L'];

class MapService extends Base {
    private mapInstance: L.Map | undefined;
    private layerGroups: Record<string, L.LayerGroup>;
    private polylines: Record<string, L.Polyline>;
    private labels: Record<string, L.Marker>;
    private currentPopup: L.Popup | undefined;
    // @ts-ignore
    private clusterGroup: L.MarkerClusterGroup;
    private renderer: L.Canvas;

    constructor() {
        super(MapService.name);
        this.layerGroups = {};
        this.polylines = {};
        this.labels = {};
        this.clusterGroup = L.markerClusterGroup();
        this.renderer = L.canvas({ padding: 0.5 });
    }

    /**
     * Sets the Leaflet map instance for the MapService.
     *
     * @param map The Leaflet map instance to set.
     */
    public setMap(map: L.Map) {
        this.log.debug("Set mapInstance for MapService!");
        this.mapInstance = map;
        this.clusterGroup = L.markerClusterGroup({
            iconCreateFunction: (cluster: any) => {
                return L.divIcon({
                    html: '<div class="flex items-center justify-center w-10 h-10 bg-white hover:bg-hellgrau shadow-lg hover:shadow-xl rounded-full group"><p class="font-bold group-hover:text-gelbgruen-100">' + cluster.getChildCount() + '</p></div>',
                    className: 'noWhiteBox'
                });
            },
            disableClusteringAtZoom: 16,
            zoomToBoundsOnClick: true,
            //polygonOptions: {opacity: 0, fill: false},
            showCoverageOnHover: false,
            maxClusterRadius: 100,
            spiderfyOnMaxZoom: false,
            spiderLegPolylineOptions: { weight: 0.0, color: '#222', opacity: 0.0 },
            chunkedLoading: true,
        }).addTo(this.mapInstance);
    }

    /**
     * Checks if the Leaflet map instance has been set.
     *
     * @returns True if the map instance is set, otherwise false.
     */
    public mapIsSet() {
        return this.mapInstance !== undefined;
    }

    /**
     * Sets a context marker at the specified position on the map.
     *
     * @param title The title of the context marker.
     * @param position The position of the context marker.
     */
    public setContextMarker(title: string, position: i_position) {
        const contextMarker: i_marker = {
            id: "zm-context-marker",
            type: MAP_TEMPLATE.MARKER,
            name: title,
            coordinates: [position.lat, position.lng],
            icon: MDI.INFO,
            iconColor: "white",
            color: "orange",
            data: {},
            context: {
                formatting: {
                    type: CONTEXT_TYPES.TEXT,
                    value: {
                        en: title,
                        de: title
                    }
                }
            }
        }
        this.removeLayer(LID.CONTEXT_MARKER);
        this.setLayersForGroup([this.buildMarker(contextMarker)], LID.CONTEXT_MARKER);
    }

    /**
     * Builds Leaflet markers based on the provided marker data.
     *
     * @param markers Array of marker data objects.
     * @returns Array of built Leaflet markers.
     */
    public buildMarkers(markers: i_marker[]) {
        const lMarkers: L.Marker[] = [] as L.Marker[];
        markers.forEach(m => {
            lMarkers.push(this.buildMarker(m));
        });
        return lMarkers;
    }

    /**
     * Builds Leaflet polylines based on the provided polyline data.
     *
     * @param polylines Array of polyline data objects.
     * @returns Array of built Leaflet polylines.
     */
    public buildPolylines(polylines: i_polyline[]) {
        const lPolylines = [] as L.Polyline[];
        polylines.forEach(p => {
            lPolylines.push(this.buildPolyline(p));
        })
        return lPolylines;
    }

    /**
     * Builds center markers for the provided polylines.
     *
     * @param lPolylines Array of Leaflet polylines.
     * @returns Array of built center markers.
     */
    public buildCenterMarkers(lPolylines: L.Polyline[]) {
        const lMarkers: L.Marker[] = [] as L.Marker[];
        lPolylines.forEach(p => {
            lMarkers.push(this.buildMarker(
                {
                    type: MAP_TEMPLATE.MARKER,
                    coordinates: [p.getCenter().lat, p.getCenter().lng],
                } as i_marker,
                [10,10]
                )
            );
        });
        return lMarkers;
    }

    /**
     * Builds a Leaflet marker based on the provided marker data.
     *
     * @param marker Marker data object.
     * @param size (Optional) Size of the marker icon.
     * @returns The built Leaflet marker.
     */
    private buildMarker(marker: i_marker, size?: [number, number]) {
        if (size === undefined) {
            size = [15,15];
        }
        // create icon for marker
        const markerIconComponent = createApp(ZmMarkerIcon, {
            icon: marker.icon,
            iconColor: marker.iconColor,
            color: marker.color
        });
        markerIconComponent.use(vuetify)
        const container = document.createElement('div');
        const mountedMarkerIconComponent= markerIconComponent.mount(container);
        const lOptions: L.MarkerOptions = {
            icon: L.divIcon({
                html: mountedMarkerIconComponent.$el,
                className: 'noWhiteBox',
                iconAnchor: size
            })
        };
        const lMarker = new L.Marker(
            marker.coordinates,
            lOptions
        );
        lMarker.on("click", () => {
            useStore().set(SID.DRAWER_STATE, true);
            useStore().set(SID.CONTEXT_STATE, true);
            this.setContextAndRefreshInterval(marker, {
                lat: lMarker.getLatLng().lat,
                lng: lMarker.getLatLng().lng
            }, LID.CONTEXT_DATA);
        });
        lMarker.on("mouseover", () => {
            if (this.currentPopup === undefined) {
                this.showPopup(lMarker.getLatLng());
                this.setContextAndRefreshInterval(marker, {
                    lat: lMarker.getLatLng().lat,
                    lng: lMarker.getLatLng().lng
                }, LID.CONTEXT_DATA_POPUP);
            }
        });
        lMarker.on("mouseout", () => {
            this.closePopup();
            usePolling().stopPoll(LID.CONTEXT_POPUP_POLL_ID);
        });
        return lMarker;
    }

    /**
     * Builds a Leaflet polyline based on the provided polyline data.
     *
     * @param polyline Polyline data object.
     * @returns The built Leaflet polyline.
     */
    private buildPolyline(polyline: i_polyline) {
        const lPolyline = new L.Polyline(
            polyline.coordinates,
            {
                color: polyline.color,
                weight: 3,
                renderer: this.renderer
            }
        )
        lPolyline.on("click", (event) => {
            useStore().set(SID.DRAWER_STATE, true);
            useStore().set(SID.CONTEXT_STATE, true);
            this.setContextAndRefreshInterval(polyline, {
                lat: lPolyline.getCenter().lat,
                lng: lPolyline.getCenter().lng
            }, LID.CONTEXT_DATA);
            L.DomEvent.stopPropagation(event);
        });
        lPolyline.on("mouseover", () => {
            if (this.currentPopup === undefined) {
                this.showPopup(lPolyline.getCenter());
                this.setContextAndRefreshInterval(polyline, {
                    lat: lPolyline.getCenter().lat,
                    lng: lPolyline.getCenter().lng
                }, LID.CONTEXT_DATA_POPUP);
            }
        });
        lPolyline.on("mouseout", () => {
            this.closePopup();
            usePolling().stopPoll(LID.CONTEXT_POPUP_POLL_ID);
        });
        return lPolyline;
    }

    /**
     * Wrapper for context creation and update interval setup.
     *
     * @param mapObject
     * @param position
     * @param lid
     * @private
     */
    private setContextAndRefreshInterval(mapObject: i_mapObject, position: i_position, lid: LID.CONTEXT_DATA | LID.CONTEXT_DATA_POPUP) {
        const lidPoll = lid === LID.CONTEXT_DATA ? LID.CONTEXT_POLL_ID : LID.CONTEXT_POPUP_POLL_ID;
        this.setContextForMapObject(mapObject, position, lid);
        if (usePolling().isPolling(lidPoll)) {
            usePolling().stopPoll(lidPoll);
        }
        usePolling().addPoll(
            lidPoll,
            () => {
                this.setContextForMapObject(mapObject, position, lid)
            },
            mapObject.context.refreshRate && !isNaN(mapObject.context.refreshRate) ? mapObject.context.refreshRate * 1000 : 60000
        );
    }

    /**
     * Sets the provided layer elements to the cluster layer of the map instance. If layers already existed in the
     * group, they will be deleted beforehand.
     *
     * @param layerElements Array of layer elements that should be written to the cluster layer.
     * @param layerGroupId Unique identifier for the layer group.
     */
    public setLayersForClusterGroup(layerElements: L.Layer[], layerGroupId: string) {
        this.log.debug("Set elements to cluster layer of mapInstance.")
        if (this.layerGroups[layerGroupId] !== undefined) {
            this.layerGroups[layerGroupId].getLayers().forEach(l => {
                l.remove();
            });
        }
        this.layerGroups[layerGroupId] = L.layerGroup(layerElements);
        this.layerGroups[layerGroupId].getLayers().forEach(l => {
            this.clusterGroup.addLayer(l);
        })
    }

    /**
     * Adds the provided layer elements to the cluster layer of the map instance.
     *
     * @param layerElements Array of layer elements to be added to the cluster layer.
     * @param layerGroupId Unique identifier for the layer group.
     */
    public addLayersToClusterGroup(layerElements: L.Layer[], layerGroupId: string) {
        this.log.debug("Add elements to cluster layer of mapInstance.")
        if (this.layerGroups[layerGroupId] == undefined) {
            this.layerGroups[layerGroupId] = L.layerGroup([]);
        }
        this.layerGroups[layerGroupId] = L.layerGroup([
            ...this.layerGroups[layerGroupId].getLayers(),
            ...layerElements
        ])
        layerElements.forEach(l => {
            this.clusterGroup.addLayer(l);
        })
    }

    /**
     * Sets the provided layer elements to the map instance.
     *
     * @param layerElements Array of layer elements to be added to the map instance.
     * @param layerGroupId Unique identifier for the layer group.
     */
    public setLayersForGroup(layerElements: L.Layer[], layerGroupId: string) {
        this.layerGroups[layerGroupId] = L.layerGroup(layerElements);
        this.layerGroups[layerGroupId].getLayers().forEach(l => {
            this.mapInstance?.addLayer(l);
        })
    }

    /**
     * Adds the provided layer element to the corresponding layer group.
     *
     * @param layerElement Layer element to be added.
     * @param layerId Unique identifier for the layer group.
     */
    public addToLayer(layerElement: L.Layer, layerId: string) {
        this.removeLayer(layerId);
        if (this.layerGroups[layerId] !== undefined) {
            this.layerGroups[layerId] = L.layerGroup([...this.layerGroups[layerId].getLayers(), layerElement]);
        } else {
            this.setLayersForClusterGroup([layerElement], layerId);
        }
        this.layerGroups[layerId].getLayers().forEach(l => {
            this.clusterGroup.addLayer(l);
        })
    }

    /**
     * Removes the layer group with the specified identifier from the map instance and cluster layer.
     *
     * @param layerId Unique identifier for the layer group to be removed.
     * @returns True if the layer group was successfully removed, otherwise false.
     */
    public removeLayer(layerId: string) {
        if (this.layerGroups[layerId] !== undefined) {
            this.log.debug(`Removed ${this.layerGroups[layerId].getLayers().length} layers`);
            this.clusterGroup.removeLayers(this.layerGroups[layerId].getLayers());
            this.layerGroups[layerId].getLayers().forEach(l => {
                this.mapInstance?.removeLayer(l);
            })
            return true;
        } else {
            return false;
        }
    }

    public setCurrentLocationMarker(position: i_position) {
        const marker: i_marker = {
            id: `zm-my-position-marker`,
            type: MAP_TEMPLATE.MARKER,
            name: {
                en: "Current Position",
                de: "Aktuelle Position"
            },
            icon: "mdi-crosshairs",
            iconColor: "white",
            color: "#00bdd2",
            coordinates: [position.lat, position.lng],
            data: {},
            context: {
                request: {
                    url: useGeocoder().buildByPositionUrl(position).toString(),
                    dataRoot: ""
                },
                formatting: {
                    type: CONTEXT_TYPES.TEXT,
                    value: {
                        en: "<b>Location:</b> {{ZM_REF[address.road]}} {{ZM_REF[address.house_number,]}}<br><b>Coordinate:</b> {{ZM_ROUND[{{ZM_REF[lat]}}]}}, {{ZM_ROUND[{{ZM_REF[lon]}}]}}",
                        de: "<b>Ort:</b> {{ZM_REF[address.road]}} {{ZM_REF[address.house_number,]}}<br><b>Koordinate:</b> {{ZM_ROUND[{{ZM_REF[lat]}}]}}, {{ZM_ROUND[{{ZM_REF[lon]}}]}}"
                    }
                }
            }
        }
        if (this.layerGroups[LID.MY_POSITION_MARKER] !== undefined) {
            if (this.layerGroups[LID.MY_POSITION_MARKER].getLayers().length > 0 && this.layerGroups[LID.MY_POSITION_MARKER].getLayers()[0] instanceof L.Marker) {
                const currentLocationMarker = this.layerGroups[LID.MY_POSITION_MARKER].getLayers()[0] as L.Marker;
                if (currentLocationMarker.getLatLng().lat !== marker.coordinates[0] || currentLocationMarker.getLatLng().lng !== marker.coordinates[1]) {
                    this.removeLayer(LID.MY_POSITION_MARKER);
                    const markers = this.buildMarkers([marker]);
                    this.setLayersForGroup(markers, LID.MY_POSITION_MARKER);
                }
            }
        } else {
            const markers = this.buildMarkers([marker]);
            this.setLayersForGroup(markers, LID.MY_POSITION_MARKER);
        }
    }

    /**
     * Adds a router marker for the specified point of interest (POI) to the map.
     *
     * @param poi The point of interest (POI) for which to add the router marker.
     * @param idx The index of the router marker.
     * @param max_idx The maximum index of the router marker.
     */
    public addRouterMarker(poi: i_poi, idx: number, max_idx: number) {
        if (poi.location !== undefined) {
            const marker: i_marker = {
                id: `routing-marker-${idx}`,
                type: MAP_TEMPLATE.MARKER,
                name: poi.name,
                icon: this.getRouterMarkerIconByIndex(idx, max_idx),
                iconColor: "white",
                color: "#006099",
                coordinates: [poi.location.lat, poi.location.lng],
                data: {},
                context: {
                    formatting: {
                        type: CONTEXT_TYPES.TEXT,
                        value: poi.name
                    }
                }
            }
            this.removeLayer(LID.ROUTER_WAYPOINT_MARKER_PREFIX + idx);
            const markers = this.buildMarkers([marker]);
            this.setLayersForGroup(markers, LID.ROUTER_WAYPOINT_MARKER_PREFIX + idx);
        }
    }

    /**
     * Gets the router marker icon based on the index and maximum index.
     *
     * @param idx The index of the router marker.
     * @param max_idx The maximum index of the router marker.
     * @returns The icon for the router marker.
     */
    public getRouterMarkerIconByIndex(idx: number, max_idx: number) {
        let icon = MDI.MARKER_START;
        if (idx != 0 && idx == max_idx) {
            // final destination x_x (flag)
            icon = MDI.MARKER_FLAG;
        } else if (idx != 0) {
            this.log.debug("set waypoint marker for idx " + idx + " of " + max_idx)
            icon = MDI.MARKER_VIA;
        }
        return icon;
    }

    /**
     * Show the popup at a specific position.
     *
     * @param position The position on the map where the popup will be created.
     */
    private showPopup(position: L.LatLng) {
        if (this.mapInstance !== undefined) {
            this.currentPopup = L.popup()
                .setLatLng(position);
            const popupComponent = createApp(ZmMapobjectContext, {
                popupMode: true
            });
            popupComponent.use(vuetify)
            const container = document.createElement('div');
            const mountedPopup= popupComponent.mount(container);
            this.currentPopup.setContent(mountedPopup.$el).openOn(this.mapInstance);
        }
    }

    /**
     * Closes the current popup.
     */
    private closePopup() {
        if (this.currentPopup) {
            this.currentPopup.close();
            this.currentPopup = undefined;
        }
    }

    /**
     * Provides store with contextual information of a map object.
     *
     * @param mapObject The map object for which to display context information.
     * @param position (deprecated) The position on the map where the context will be shown.
     * @param lid The LID of either the context of the sidebar or the popup
     */
    private setContextForMapObject(mapObject: i_mapObject, position: i_position, lid: LID.CONTEXT_DATA | LID.CONTEXT_DATA_POPUP) {
        if (mapObject.context.request !== undefined) {
            // show loading if the current data is not of the same map object
            useStore().set(lid, {
                id: mapObject.id,
                title: mapObject.name,
                icon: mapObject.icon,
                color: mapObject.color
            } as i_contextScreenData);
            if (this.log.willDebug()) {
                this.log.debug(`fetching data for this mapObject: ${JSON.stringify(mapObject)}`);
            }
            useFetchWorker().getContextForMapObject(mapObject)
                .then(result =>  {
                    if (this.log.willDebug()) {
                        this.log.debug("Fetched context for '" + mapObject.name + "': " + JSON.stringify(result));
                    }
                    const contextData = {
                        id: mapObject.id,
                        title: mapObject.name,
                        icon: mapObject.icon,
                        color: mapObject.color,
                        context: result,
                        position: position
                    } as i_contextScreenData;
                    if (JSON.stringify(useStore().get(lid, {})) !== JSON.stringify(contextData)) {
                        useStore().set(lid, contextData);
                    }
                })
                .catch(error => {
                    this.log.error("Error during context fetching: " + error)
                })
        } else {
            const staticContext: i_context = {
                type: mapObject.context.formatting.type,
                value: mapObject.context.formatting.value
            };
            useStore().set(lid, {
                id: mapObject.id,
                title: mapObject.name,
                icon: mapObject.icon,
                color: mapObject.color,
                context: staticContext,
                position: position
            } as i_contextScreenData);
        }
    }

    /**
     * Draws a polyline on the map with the specified color and optional label.
     *
     * @param polyline Array of latitude and longitude coordinates defining the polyline.
     * @param layerId Unique identifier for the polyline layer.
     * @param color Color of the polyline.
     * @param label (Optional) Label configuration object for the polyline.
     * @returns The drawn polyline.
     */
    public drawPolyline(polyline: [number, number][], layerId: string, color: string, label?: i_label) {
        this.log.debug(`draw polyline on layer ${layerId}`);
        const line = L.polyline(polyline, { color: color });
        if (this.polylines[layerId] !== undefined) {
            this.mapInstance?.removeLayer(this.polylines[layerId]);
        }
        this.polylines[layerId] = line;
        this.mapInstance?.addLayer(this.polylines[layerId]);
        if (label !== undefined) {
            label.color = label.color == undefined ? color : label.color;
            const lLabel = this.buildLabel([line.getCenter().lat, line.getCenter().lng], label, layerId + ":label");
            this.setLayersForGroup([lLabel], layerId + ":label");
        }
        return line;
    }

    /**
     * Builds a label marker on the map at the specified position with the provided configuration.
     *
     * @param position Array of latitude and longitude coordinates for the label marker position.
     * @param labelConfig Configuration object for the label marker.
     * @param layerId Unique identifier for the label marker layer.
     * @param clickEvent (Optional) Click event handler function for the label marker.
     * @returns The built label marker.
     */
    public buildLabel(position: [number, number], labelConfig: i_label, layerId: string, events?: Record<string, Function>) {
        const labelMarkerComponent = createApp(ZmMapLabel, {
            label: Text.buildFromString(labelConfig.name ? labelConfig.name : ""),
            textColor: labelConfig.iconColor,
            color: labelConfig.color,
            icon: labelConfig.icon
        });
        labelMarkerComponent.use(vuetify)
        const container = document.createElement('div');
        container.id = layerId;
        const mountedLabelMarkerComponent= labelMarkerComponent.mount(container);
        const options: L.MarkerOptions = {
            icon: L.divIcon({
                html: mountedLabelMarkerComponent.$el,
                className: 'noWhiteBox',
                iconSize: [
                    labelConfig.width !== undefined ? labelConfig.width : 70,
                    labelConfig.height !== undefined ? labelConfig.height : 35
                ]
            })
        };
        const lLabel = new L.Marker(new L.LatLng(position[0], position[1]), options);
        if (events) {
            for (const key of Object.keys(events)) {
                lLabel.on(key, (event) => {
                    if (events[key] !== undefined) {
                        events[key]();
                        L.DomEvent.stopPropagation(event);
                    }
                })
            }
        }
        this.labels[layerId] = lLabel;
        return lLabel;
    }

    /**
     * Draws a route on the map based on the provided route data.
     * Removes any existing route before drawing the new route.
     *
     * @param route The route data containing segments to be drawn.
     */
    public drawRoute(route: i_route) {
        this.removeRoute();
        let bounds: L.LatLngBounds | undefined;
        route.segments.forEach((s: i_routeSegment, is: number) => {
            const polyline = this.drawPolyline(
                s.polyline,
                LID.CURRENT_ROUTE + "-" + is,
                motColors[s.mode],
                {
                    name: StringUtils.fromDuration(s.duration),
                    icon: motIcons[s.mode],
                    width: Math.floor(s.duration/(60*60*1000)) > 0 ? 90 : 75,
                } as i_label
            );
            if (bounds === undefined) {
                bounds = polyline.getBounds();
            } else {
                bounds.extend(polyline.getBounds());
            }
        });
        if (bounds !== undefined) {
            this.mapInstance?.fitBounds(bounds, {padding: [50,50]});
        }
    }

    /**
     * Removes the current route and its associated elements from the map.
     */
    public removeRoute() {
        let i = 0;
        let routeSegmentsAreLeft = true;
        do {
            if (this.polylines[LID.CURRENT_ROUTE + "-" + i] !== undefined) {
                this.polylines[LID.CURRENT_ROUTE + "-" + i].remove();
                this.labels[LID.CURRENT_ROUTE + "-" + i + ":label"].remove();
                i += 1;
            } else {
                routeSegmentsAreLeft = false;
            }
        } while (routeSegmentsAreLeft);
    }

    /**
     * Removes all label markers from the map.
     */
    public removeAllLabels() {
        Object.values(this.labels).forEach(p => {
            p.remove();
        });
        this.labels = {};
    }

    /**
     * Removes all polylines from the map.
     */
    public removeAllPolylines() {
        Object.values(this.polylines).forEach(p => {
            p.remove();
        });
        this.polylines = {};
    }

    /**
     * Invalidates the map size, forcing it to recalculate its size.
     * Useful when the map container size changes dynamically.
     */
    public rerenderMap() {
        this.mapInstance?.invalidateSize();
    }

    /**
     * Changes the container of the map to the one specified by ID.
     *
     * @param newContainerId The ID of the new container element.
     */
    public rebindToContainerById(newContainerId: string, newTileUrl: string) {
        const newContainer = document.getElementById(newContainerId);
        if (newContainer && this.mapInstance) {
            this.mapInstance.getContainer().parentNode?.removeChild(this.mapInstance.getContainer()); // Remove map from old container
            newContainer.appendChild(this.mapInstance.getContainer()); // Append map to new container
            this.changeTileUrl(newTileUrl);
            this.rerenderMap(); // Update map size
        } else {
            this.log.error('Container not found');
        }
    }

    /**
     * Changes the current url providing the leaflet tiles.
     *
     * @param newTileUrl is the url to the tile server that should be used
     */
    public changeTileUrl(newTileUrl: string) {
        if (this.mapInstance) {
            this.log.debug("Refresh Tile URL!");
            this.mapInstance.eachLayer((l) => {
                if (l instanceof L.TileLayer) {
                    this.log.debug("Remove old tile layer!");
                    this.mapInstance?.removeLayer(l);
                }
            });
            this.log.debug("Add new tile layer!");
            this.mapInstance.addLayer(L.tileLayer(newTileUrl, {
                maxZoom: 19,
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            }));
        }
    }

    /**
     * Returns the leaflet map instance.
     *
     * @returns Map or undefined
     */
    public getInstance() {
        return this.mapInstance;
    }
}

const mapService = new MapService();

export const useMap = (): MapService => {
    return mapService;
}
