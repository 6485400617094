import {reactive, Ref, ref, watch} from "vue";
import {getStorage, setStorage} from "@/utils/storage";
import {Base} from "@/Base";

class StoreService extends Base {
    private storeMap:Map<string,any> = reactive(new Map());
    private store:Record<string, any> = {};

    constructor() {
        super(StoreService.name);
    }

    public setAndStore(id:string,val:any) {
        setStorage(id, val);
        this.set(id, val);
    }

    public set(id:string, val:any) {
        //this.storeMap.set(id, val);
        if (this.store[id] !== undefined) {
            this.store[id].value = val;
        } else {
            this.store[id] = ref(val);
        }
    }

    public add(id:string, val:any) {
        if (this.store[id] !== undefined) {
            if (Array.isArray(val)) {
                this.store[id].value = [...this.store[id].value, ...val];
            } else {
                this.store[id].value = [...this.store[id].value, val];
            }
        } else {
            this.set(id, []);
            this.add(id, val);
        }
    }

    public removeFrom(id: string, val: any) {
        if (this.store[id] !== undefined) {
            if (Array.isArray(this.store[id].value)) {
                if (Array.isArray(val)) {
                    this.log.debug("Remove array from '" + id + "'.");
                    this.store[id].value = (this.store[id].value as []).filter(f => !val.map(e => JSON.stringify(e)).includes(JSON.stringify(f)));
                } else {
                    this.log.debug("Remove value from '" + id + "'.");
                    this.store[id].value = (this.store[id].value as []).filter(f => JSON.stringify(val) !== JSON.stringify(f));
                }
            } else if (JSON.stringify(this.store[id].value) == JSON.stringify(val)) {
                this.log.debug("Remove value from non-array '" + id + "', setting it to undefined.");
                this.storeMap.set(id, undefined);
            }
        }
    }

    public get<T>(id:string, def:T): T {
        //return this.storeMap.get(id) != undefined ? this.storeMap.get(id) : def;
        return this.store[id] !== undefined ? this.store[id].value : def;
    }

    public ref<T>(id: string, def?: T): Ref<T> {
        if (this.store[id] === undefined) {
            this.store[id] = ref(def);
        }
        return this.store[id];
    }

    public load(id:string, def:any) {
        this.set(id, getStorage(id, def));
    }

    public holds(id:string) {
        return this.storeMap.get(id) !== undefined;
    }
}

const storeService: StoreService = new StoreService();

export const useStore = (): StoreService => {
    return storeService;
}
