import { createRouter, createWebHistory } from 'vue-router'
import {Text} from "@/utils/text";
import {BASE_URL} from "@/constants";

const router = createRouter({
  history: createWebHistory(BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import("@/views/zm-view-map.vue")
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import("@/views/zm-view-grafana.vue")
    },
    {
      path: '/impressum',
      name: 'Impressum',
      component: () => import('@/views/zm-view-legal-notice.vue')
    },
    {
      path: '/:pathMatch(.*)',
      name: 'not found',
      component: () => import('@/views/zm-view-text-page.vue'),
      props: {
        header: new Text({
          en: "Error 404",
          de: "Fehler 404"
        }),
        msg: new Text({
          en: "This page does not exist!",
          de: "Diese Seite existiert nicht!"
        })
      }
    }
  ]
})

export default router
