export const setStorage = (key:string, value:any) => {
    const json = {
        value: value,
    }
    localStorage.setItem(key, JSON.stringify(json));
}

export const getStorage = (key:string, def:any) => {
    const json:(string | null) = localStorage.getItem(key);
    try {
        return json ? JSON.parse(json).value : def;
    } catch (e) {
        return def;
    }
}
