import * as Comlink from "comlink";
import {Remote} from "comlink";
import {i_fileSourceDto, i_request, i_sourceDto} from "@/types/interfaces/structure.interfaces";
import {AxiosRequestConfig} from "axios";
import {FeatureCollection} from "@turf/turf";
import {i_context, i_fetchedMapObjects, i_mapObject} from "@/types/interfaces/frontend.interfaces";
import {i_axiosOptions} from "@/types/interfaces/workers.interfaces";

type FetchWorker = Remote<{
    setCsrfToken: (csrfToken: string) => Promise<void>;
    setAccessToken: (accessToken: string) => Promise<void>;
    clearStorageFor: (id: string) => Promise<void>;
    fetchRequest: (request: i_request, headers?: any) => Promise<any>;
    axiosRequest: (config: AxiosRequestConfig, options?: i_axiosOptions) => Promise<{ status: number; data: any } | { status: number; message: string }>;
    getGeojsonForFileSources: (sources: i_fileSourceDto[]) => FeatureCollection;
    getMapObjectsForSource: (source: i_sourceDto) => i_fetchedMapObjects;
    getContextForMapObject: (mapObject: i_mapObject) => i_context;
    replaceByMatrix: (obj:any, data:any) => any;
}>;

const workerInstance = new Worker(
    new URL("@/services/fetch.worker", import.meta.url),
    {type: 'module'});

const fetchWorker: FetchWorker = Comlink.wrap(workerInstance);

export const useFetchWorker = () => {
    return fetchWorker;
}
