import {ref, Ref} from "vue";
import {i_snack} from "@/types/interfaces/frontend.interfaces";
import {i_text} from "@/types/interfaces/structure.interfaces";
import {Base} from "@/Base";


const STD_COLOR_ERROR = "#f62e36";
const STD_COLOR_INFO = "#80cc28";
const STD_COLOR_WARN = "#fcc812";

class SnackbarService extends Base {
    private snack: Ref<i_snack>;

    constructor() {
        super(SnackbarService.name);
        this.snack = ref({
            msg: "",
            color: "",
            icon: "",
            type: {
                en: "",
                de: ""
            },
            state: false
        });
    }

    public error(msg: string) {
        this.issueEvent(msg, STD_COLOR_ERROR, {
            en: "Error",
            de: "Fehler"
        }, "mdi-alert-outline");
    }

    public warn(msg: string) {
        this.issueEvent(msg, STD_COLOR_WARN, {
            en: "Warning",
            de: "Warnung"
        }, "mdi-message-alert-outline");
    }

    public info(msg: string) {
        this.issueEvent(msg, STD_COLOR_INFO, {
            en: "Info",
            de: "Info"
        }, "mdi-information-outline");
    }

    private issueEvent(msg: string, color: string, type: i_text, icon: string) {
        this.snack.value = {
            msg,
            color,
            type,
            icon,
            state: true
        }
    }

    public getSnack() {
        return this.snack.value;
    }
}

const snackbarService = new SnackbarService();

export const useSnackbar = () => {
    return snackbarService;
}
