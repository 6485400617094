import {
    i_mapObject,
    i_mapSettings,
    i_marker,
    i_polyline,
    i_zmIfExpression,
    i_keycloakConfig,
    i_keycloakTokenResponse,
    i_keycloakUserProfile,
    i_cachedResponse,
    i_response, i_error, i_fetchable
} from "../interfaces/frontend.interfaces";
import {
    i_formattingTable,
    i_formattingText, i_table,
    t_formatting
} from "@/types/interfaces/structure.interfaces";
import {isEntryContext, isText} from "@/types/typeguards/general.guards";

const isMapObject = (obj: i_mapObject): obj is i_mapObject => {
    return obj !== undefined &&
        'type' in obj &&
        'name' in obj &&
        'coordinates' in obj &&
        'data' in obj &&
        'context' in obj &&
        'color' in obj &&
        'icon' in obj &&
        typeof obj.name === 'string' &&
        typeof obj.color === 'string' &&
        typeof obj.icon === 'string' &&
        (obj.iconColor === undefined || typeof obj.iconColor === 'string') &&
        Array.isArray(obj.coordinates) &&
        isEntryContext(obj.context);
}

export const isMarker = (obj: i_marker): obj is i_marker => {
    return isMapObject(obj) &&
        obj.coordinates.length == 2 &&
        typeof obj.coordinates[0] === 'number' &&
        typeof obj.coordinates[1] === 'number';
}

export const isPolyline = (obj: i_polyline): obj is i_polyline => {
    return isMapObject(obj) &&
        obj.coordinates.every(c =>
            Array.isArray(c) &&
            c.length == 2 &&
            typeof c[0] === 'number' &&
            typeof c[1] === 'number'
        )
}

export const isFormatText = (obj: t_formatting): obj is i_formattingText => {
    return obj !== undefined &&
        (obj as i_formattingText).type.toLowerCase() == "text";
}

export const isFormatTable = (obj: t_formatting): obj is i_formattingTable => {
    return obj !== undefined &&
        (obj as i_formattingTable).type.toLowerCase() == "table";
}

export const isTable = (obj: any): obj is i_table => {
    return obj !== undefined && typeof obj === "object" &&
        Array.isArray(obj.columns) && Array.isArray(obj.rows) &&
        obj.columns.every((c: any) =>
            isText(c.name) &&
            (c.sort === undefined || (
                    typeof c.sort === "string" && (c.sort.toLowerCase() === "up" || c.sort.toLowerCase() === "down")
                )
            ) &&
            (c.description === undefined || isText(c.description))
        ) &&
        obj.rows.every((r: any[]) =>
            r.every((e: any) =>
                typeof e === "string" || typeof e === "number"
            )
        );
}

export function isZmIfExpression(obj: any): obj is i_zmIfExpression {
    return obj !== undefined &&
        ('gt' in obj || 'ge' in obj || 'lt' in obj || 'le' in obj || 'eq' in obj) &&
        (obj.gt !== undefined ||
            obj.ge !== undefined ||
            obj.lt !== undefined ||
            obj.le !== undefined ||
            obj.eq !== undefined) &&
        'value' in obj &&
        'true' in obj &&
        'false' in obj;
}

export function isMapSettings(obj: any): obj is i_mapSettings {
    return obj !== undefined &&
        (obj.title === undefined || typeof obj.title === 'string') &&
        (obj.startPosition === undefined || (Array.isArray(obj.startPosition) && obj.startPosition.length === 2 &&
            typeof obj.startPosition[0] === 'number' && typeof obj.startPosition[1] === 'number')) &&
        (obj.startZoom === undefined || typeof obj.startZoom === 'number') &&
        (obj.storeLastPosition === undefined || typeof obj.storeLastPosition === 'boolean') &&
        (obj.debug === undefined || (
            typeof obj.debug === "boolean" ||
            typeof obj.debug === "string" ||
            (Array.isArray(obj.debug) && obj.debug.every((e: any) => typeof e === "string"))
        )) &&
        (obj.keycloak === undefined || isKeycloakConfig(obj.keycloak));
}

export const isKeycloakConfig = (obj:any): obj is i_keycloakConfig => {
    return obj &&
        "realm" in obj && typeof obj.realm === "string" &&
        "clientId" in obj && typeof obj.clientId === "string";
}

export const isKeycloakTokenResponse = (obj:any): obj is i_keycloakTokenResponse => {
    return obj && typeof obj === 'object' &&
        'access_token' in obj && typeof obj.access_token === 'string' &&
        'expires_in' in obj && typeof obj.expires_in === 'number' &&
        'refresh_expires_in' in obj && typeof obj.refresh_expires_in === 'number' &&
        'refresh_token' in obj && typeof obj.refresh_token === 'string' &&
        'token_type' in obj && typeof obj.token_type === 'string' &&
        'id_token' in obj && typeof obj.id_token === 'string' &&
        'session_state' in obj && typeof obj.session_state === 'string' &&
        'scope' in obj && typeof obj.scope === 'string';
}

export const isKeycloakUserInfo = (obj:any): obj is i_keycloakUserProfile => {
    return obj && typeof obj === 'object' &&
        'sub' in obj && typeof obj.sub === 'string' &&
        'email_verified' in obj && typeof obj.email_verified === 'boolean' &&
        'name' in obj && typeof obj.name === 'string' &&
        'preferred_username' in obj && typeof obj.preferred_username === 'string' &&
        'given_name' in obj && typeof obj.given_name === 'string' &&
        'family_name' in obj && typeof obj.family_name === 'string' &&
        'email' in obj && typeof obj.email === 'string';
}

export const isCachedResponse = (obj: any): obj is i_cachedResponse => {
    return obj && typeof obj === 'object' &&
        'timestamp' in obj && typeof obj.timestamp === 'number' &&
        'data' in obj;
}

export const isResponse = (obj: any): obj is i_response => {
    return obj && typeof obj === 'object' &&
        'status' in obj && typeof obj.status === 'number' && obj.status < 400 &&
        'data' in obj;
}

export const isError = (obj: any): obj is i_error => {
    return obj && typeof obj === 'object' &&
        'status' in obj && typeof obj.status === 'number' && obj.status >= 400 &&
        'message' in obj && typeof obj.message === 'string';
}

export const isWorkerAxiosSuccess = (obj: any): obj is { status: number, data: any } => {
    return obj !== undefined &&
        'status' in obj && typeof obj.status === 'number' && obj.status < 400 &&
        'data' in obj;
}

export const isWorkerAxiosError = (obj: any): obj is { status: number, message: string } => {
    return obj !== undefined &&
        'status' in obj && typeof obj.status === 'number' && obj.status >= 400 &&
        'message' in obj && typeof obj.message === 'string';
}

export const isFetchable = (obj: any): obj is i_fetchable => {
    return (
        obj !== undefined && obj !== null &&
        typeof obj === 'object' &&
        typeof obj.id === 'string' &&
        typeof obj.url === 'string' &&
        (obj.type === 'HTTP' || obj.type === 'MQTT') &&
        (obj.cachingRate === undefined || typeof obj.cachingRate === 'number') &&
        (obj.dataRoot === undefined || typeof obj.dataRoot === 'string') &&
        (
            obj.permission === undefined ||
            typeof obj.permission === 'boolean' ||
            typeof obj.permission === 'string' ||
            Array.isArray(obj.permission)
        ) &&
        (obj.useAuthOf === undefined || typeof obj.useAuthOf === 'string')
        // No strict check for `auth`, so it's not validated
    );
}
