import {i_text} from "@/types/interfaces/structure.interfaces";
import {
    i_button,
    i_buttonOptions,
    i_container,
    i_genericDialog,
} from "@/types/interfaces/dialog.interfaces";
import {useDialog} from "@/services/dialog.service";
import {ITEM} from "@/types/enums/dialog.enums";
import {buildContainer} from "@/builder/container.builder";
import {Base} from "@/Base";

const STD_COLOR = "#80cc28";
const STD_WIDTH = 350;

class DialogBuilder extends Base {
    private genericDialog: i_genericDialog;

    constructor() {
        super(DialogBuilder.name);
        this.genericDialog = {
            color: STD_COLOR,
            width: STD_WIDTH,
            title: {
                en: "Dialog",
                de: "Meldung"
            },
            content: buildContainer().build(),
            actions: []
        }
    }

    public setColor(color: string): DialogBuilder {
        this.genericDialog.color = color;
        return this;
    }

    public setTitle(title: i_text): DialogBuilder {
        this.genericDialog.title = title;
        return this;
    }

    public setWidth(width: number): DialogBuilder {
        this.genericDialog.width = width;
        return this;
    }

    public setContent(content: i_container): DialogBuilder {
        this.genericDialog.content = content;
        return this;
    }

    public addAction(title: i_text, onClick: Function, options?: i_buttonOptions): DialogBuilder {
        this.genericDialog.actions.push({
            type: ITEM.BUTTON,
            value: {
                title,
                onClick,
                options
            }
        } as i_button);
        return this;
    }

    public addCancelAction(): DialogBuilder {
        return this.addAction({
                en: "Cancel",
                de: "Abbrechen"
            },
            () => useDialog().close()
        );
    }

    public addConfirmAction(confirmCallback?: Function): DialogBuilder {
        return this.addAction({
                en: "Confirm",
                de: "Bestätigen"
            },
            () => {
                if (confirmCallback !== undefined) {
                    confirmCallback();
                }
                useDialog().close()
            });
    }

    public build(): i_genericDialog {
        return this.genericDialog;
    }
}



export const buildDialog = () => {
    return new DialogBuilder();
}
