import {LANGUAGE} from "@/types/enums/frontend.enums";
import {i_text} from "@/types/interfaces/structure.interfaces";

export const _LANGUAGES: Record<LANGUAGE, i_text> = {
    [LANGUAGE.GERMAN]: {
        de: "Deutsch",
        en: "German"
    },
    [LANGUAGE.ENGLISH]: {
        de: "Englisch",
        en: "English"
    }
}
