// Vuetify
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import {Log} from "@/utils/log";
import axios from "axios";
import {useStore} from "@/services/store.service";
import {useFetchWorker} from "@/services/fetch.worker.service";
import {vuetify} from "@/vuetify.config";
import {SID} from "@/types/enums/frontend.enums";
import {DataUtils} from "@/utils/data.utils";
import {createApp} from "vue";
import App from "@/App.vue";
import router from "@/router";
import {useConfig} from "@/services/config.service";
import {useKeycloak} from "@/services/keycloak.service";

await useConfig().init();

const log = new Log("main");

await axios.get("/api/csrf-token").then((response) => {
    const csrfToken = response.data.token
    axios.defaults.headers.common['x-csrf-token'] = csrfToken;
    useFetchWorker().setCsrfToken(csrfToken).then(() => {
        log.debug("CSRF token was passed to worker.");
    }, (err: any) => {
        log.error("A problem occured while forwarding the csrf token to the worker!")
    })
}, (err) => {
    log.error(err)
})

// replacement matrix
useStore().set(SID.REPLACEMENT_MATRIX, DataUtils.REP_MATRIX);

// init keycloak
const kcConfig = useConfig().getKeycloak();
if (kcConfig) {
    useKeycloak().init(kcConfig).then(kcState => {
        log.debug("Keycloak Service initialized!");
    });
}

const app = createApp(App);
app.use(vuetify);
app.use(router);

app.mount('#app');
