import Keycloak, { KeycloakInstance, KeycloakInitOptions } from 'keycloak-js';
import {i_keycloakConfig, i_keycloakUserProfile} from "@/types/interfaces/frontend.interfaces";
import * as qs from "qs";
import axios from "axios";
import {isKeycloakTokenResponse, isKeycloakUserInfo} from "@/types/typeguards/frontend.guards";
import {ref, Ref} from "vue";
import {useFetchWorker} from "@/services/fetch.worker.service";
import {t_permission} from "@/types/interfaces/structure.interfaces";
import {Base} from "@/Base";
import {useConnection} from "@/services/connection.service";

class KeycloakService extends Base {
    private keycloak: KeycloakInstance | undefined;
    private tokenRefreshTimeout: number | undefined;
    private storage: Storage;
    private keycloakUserInfo: Ref<i_keycloakUserProfile | undefined>;
    private keycloakUserRoles: Ref<string[]>;

    constructor() {
        super(KeycloakService.name);
        this.keycloakUserInfo = ref(undefined);
        this.keycloakUserRoles = ref([]);
        this.storage = sessionStorage; //config.useLocalStorage ? localStorage : sessionStorage;
    }

    public async init(config: i_keycloakConfig, options: KeycloakInitOptions = {}): Promise<boolean> {
        try {
            if (config === undefined) {
                throw "Cannot setup KeycloakService, missing config!";
            }

            this.keycloak = new Keycloak({
                url: "/keycloak",
                realm: config.realm,
                clientId: config.clientId,
            });

            const token = this.storage.getItem('kc_token');
            const refreshToken = this.storage.getItem('kc_refreshToken');
            const idToken = this.storage.getItem('kc_idToken');

            const authenticated = await this.keycloak.init({
                onLoad: 'check-sso', // Check if the user is already authenticated without requiring a full login
                silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html', // Page used for silent SSO
                pkceMethod: 'S256', // Optional: Use PKCE for better security
                checkLoginIframe: false,
                token: token ? token : undefined,
                refreshToken: refreshToken ? refreshToken : undefined,
                idToken: idToken ? idToken : undefined,
                ...options
            });

            if (authenticated) {
                await this.loadUserInfo();
                this.storeTokens();
                this.refreshToken();
            } else {
                this.clearStoredTokens();
            }

            return authenticated;
        } catch (error) {
            console.error('Failed to initialize Keycloak:', error);
            return false;
        }
    }

    public async login(username: string, password: string): Promise<void> {
        if (this.keycloak) {
            try {
                const loginData = qs.stringify({
                    'client_id': this.keycloak.clientId,
                    'username': username,
                    'password': password,
                    'scope': 'openid',
                    'grant_type': 'password'
                });

                const response = await axios.post(
                    `/keycloak/realms/${this.keycloak.realm}/protocol/openid-connect/token`,
                        loginData,
                    {
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            }
                        }
                    );

                if (isKeycloakTokenResponse(response.data)) {
                    this.keycloak.token = response.data.access_token;
                    this.keycloak.refreshToken = response.data.refresh_token;
                    this.keycloak.idToken = response.data.id_token;
                    await this.keycloak.updateToken();
                    this.storeTokens();
                    this.refreshToken();
                } else {
                    this.log.error("Invalid response format!");
                }

                await useConnection().setupRequest();

                await this.loadUserInfo();
            } catch (error) {
                this.log.error(`Failed to login: ${error}`);
                throw error;
            }
        } else {
            this.log.error(`Keycloak is not initialized yet!`);
        }
    }

    private async loadUserInfo() {
        if (this.keycloak) {
            const userInfo = await this.keycloak.loadUserInfo();
            if (isKeycloakUserInfo(userInfo)) {
                this.keycloakUserInfo.value = userInfo;
            } else {
                this.log.error(`Cannot retrieve valid user info: ${userInfo}`);
            }
        } else {
            this.log.error(`Keycloak is not initialized yet!`);
        }
    }

    private refreshToken(): void {
        if (this.keycloak) {
            if (this.tokenRefreshTimeout) {
                clearTimeout(this.tokenRefreshTimeout);
            }
            const timespan = (this.keycloak?.tokenParsed?.exp ? this.keycloak.tokenParsed.exp * 1000 : 0) - Date.now() - (30 * 1000);
            this.tokenRefreshTimeout = window.setTimeout(() => {
                this.keycloak?.updateToken(55).then((refreshed) => {
                    this.log.debug(`Token was refreshed: ${refreshed}`);
                    if (refreshed) {
                        this.storeTokens();
                        this.refreshToken();
                    }
                }).catch((error) => {
                    this.refreshToken();
                    this.log.error(`Failed to refresh token ${error}, retry in 3 seconds...`);
                });
            }, timespan > 0 ? timespan : 3000);
        }
    }

    public getToken(): string | undefined {
        if (this.keycloak) {
            return this.keycloak.token;
        } else {
            this.log.error(`Keycloak is not initialized yet!`);
            return undefined;
        }
    }

    public hasRole(role: string): boolean {
        if (this.keycloak) {
            return this.keycloakUserRoles.value.includes(role);
        } else {
            this.log.error(`Keycloak is not initialized yet!`);
            return false;
        }
    }

    public isPermitted(permission: t_permission) {
        if (this.keycloak) {
            if (Array.isArray(permission)) {
                return permission.some(p => this.hasRole(p));
            } else if (typeof permission === "string") {
                return this.hasRole(permission);
            } else if (this.getToken()) {
                return true;
            }
        }
        return false;
    }

    public getUserInfo(): i_keycloakUserProfile | undefined {
        return this.keycloakUserInfo.value;
    }

    public async logout(): Promise<void> {
        this.keycloakUserInfo.value = undefined;
        this.keycloakUserRoles.value = [];
        if (this.keycloak) {
            try {
                await this.keycloak.logout();
                this.clearStoredTokens();
                if (this.tokenRefreshTimeout) {
                    clearTimeout(this.tokenRefreshTimeout);
                }
            } catch (error) {
                this.log.error(`Failed to logout ${error}`);
            }
        } else {
            this.log.error(`Keycloak is not initialized yet!`);
        }
    }

    private storeTokens(): void {
        if (this.keycloak?.token && this.keycloak?.refreshToken && this.keycloak?.idToken) {
            const roles = this.keycloak?.tokenParsed?.realm_access?.roles;
            this.keycloakUserRoles.value = roles ? roles : [];
            this.storage.setItem('kc_token', this.keycloak.token);
            this.storage.setItem('kc_refreshToken', this.keycloak.refreshToken);
            this.storage.setItem('kc_idToken', this.keycloak.idToken);
            useFetchWorker().setAccessToken(this.keycloak.token).then(() => {
                this.log.debug("Access token passed to worker!");
            }).catch((err) => {
                this.log.error(`Cannot pass access token to worker: ${err}`);
            });
        }
    }

    private clearStoredTokens(): void {
        this.storage.removeItem('kc_token');
        this.storage.removeItem('kc_refreshToken');
        this.storage.removeItem('kc_idToken');
    }
}

const keycloakService = new KeycloakService();
export const useKeycloak = () => {
    return keycloakService;
}
