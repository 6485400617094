export class FileUtils {
    static createDownloadFile(obj: object, filename: string) {
        // Convert the obj to JSON, create a blob from it and build temporary url
        const json = JSON.stringify(obj);
        const blob = new Blob([json], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        // Create a link element
        const link = document.createElement('a');
        // Set the link's attributes
        link.href = url;
        link.download = `${filename}.json`;
        // Append the link to the document body, click the link programmatically to trigger the download, then remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // Revoke the URL to free up memory
        URL.revokeObjectURL(url);
    }
}
