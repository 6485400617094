import {i_entryDto, i_request, i_sourceDto, i_text} from "@/types/interfaces/structure.interfaces";
import {buildStepper} from "@/builder/stepper.builder";
import {DataUtils} from "@/utils/data.utils";
import {computed, ref, Ref} from "vue";
import {CONTEXT_TYPES, MAP_TEMPLATE, USER_ROLES} from "@/types/enums/general.enums";
import {LANGUAGE, SID, THEME} from "@/types/enums/frontend.enums";
import {StringUtils} from "@/utils/string.utils";
import {buildContainer} from "@/builder/container.builder";
import {buildTabs} from "@/builder/tabs.builder";
import {Text} from "@/utils/text";
import {useConnection} from "@/services/connection.service";
import {FetchWorkerUtils} from "@/utils/fetch.worker.utils";
import {useStore} from "@/services/store.service";
import {useSnackbar} from "@/services/snackbar.service";
import {buildDialog} from "@/builder/dialog.builder";
import {useDialog} from "@/services/dialog.service";
import {Log} from "@/utils/log";
import {isRequest, isText} from "@/types/typeguards/general.guards";
import {isFetchable} from "@/types/typeguards/frontend.guards";

const log = new Log("entry.dialog");

export const entryDialog = (submitCallback: Function, options?: { data?: i_entryDto, submitText?: i_text, title?: i_text }) => {
    const data = options?.data;
    const stepper = buildStepper();
    // STEP 1: Button Styling
    const refsButtonTitle = DataUtils.createLangRefs();
    if (isText(data?.name)) {
        Object.values(LANGUAGE).forEach(language => {
            refsButtonTitle[language].value = data?.name[language];
        });
    }
    const refsButtonDesc = DataUtils.createLangRefs();
    if (isText(data?.info?.description)) {
        Object.values(LANGUAGE).forEach(language => {
            refsButtonDesc[language].value = data?.info?.description[language];
        });
    }
    const refButtonColor = ref(data?.color);
    const refButtonIcon = ref(data?.icon);
    const refButtonSourceUrl = ref(data?.info?.link);
    const permissions = isRequest(data?.sources[0]?.request) ? data?.sources[0]?.request?.permission : undefined;
    const refButtonPermissions = ref(permissions === undefined ? [] : Array.isArray(permissions) ? permissions : [ typeof permissions === "string" ? permissions : "user" ]);
    const refPermissionOptions = ref(Object.values(USER_ROLES));
    const refsButtonTitleError = Object.values(LANGUAGE).reduce((previous, language) => {
        return { ...previous, [language]: computed(() => refsButtonTitle[language].value === undefined || refsButtonTitle[language].value === "") }
    }, {} as Record<LANGUAGE, Ref<boolean>>);
    const refButtonIconError = computed(() => !(refButtonIcon.value !== undefined && refButtonIcon.value !== ""));
    const refButtonColorError = computed(() => !(refButtonColor.value !== undefined && refButtonColor.value !== ""));
    const refButtonSourceUrlError = computed(() => !(StringUtils.isURL(refButtonSourceUrl.value) || refButtonSourceUrl.value === undefined || refButtonSourceUrl.value === ""));
    stepper.addStep(
        {
            en: "Button",
            de: "Schaltfläche"
        },
        "mdi-gesture-tap-button",
        buildContainer()
            .addHeader({
                en: "Button Styling",
                de: "Visualisierung der Schaltfläche"
            })
            .addDesc({
                en: "Select an icon, the coloring and the authorization level for the data. Furthermore you may write a general description for the data and provide a link to the data source. Finally, you may set user roles that are allowed to access the data. Notice; by defining no roles the data will be available to everyone.",
                de: "Wähle ein Icon, die Färbung und die Berechtigungsstufe für die Daten. Weiterhin kann eine allgemeine Beschreibung für die Daten geschrieben werden und ein Link zur Datenquelle angegeben werden. Schließlich können berechtigte Rollen angegeben werden, wobei die Daten, bei Angabe keiner Rolle, für jeden öffentlich zugänglich sind."
            })
            .addContainer(
                buildContainer()
                    .setHorizontal()
                    .addInput(
                        {
                            en: "Icon",
                            de: "Symbol"
                        },
                        refButtonIcon,
                        {
                            prependInnerIcon: refButtonIcon,
                            cols: 3,
                            error: refButtonIconError,
                            readonly: ref(true)
                        }
                    )
                    .addIconPicker(
                        refButtonIcon,
                        {
                            size: "large",
                            block: true
                        }
                    )
                    .addInput(
                        {
                            en: "Color",
                            de: "Farbe"
                        },
                        refButtonColor,
                        {
                            prependInnerIcon: computed(() => refButtonColor.value !== undefined && refButtonColor.value !== "" ? "mdi-circle" : undefined),
                            innerIconColor: refButtonColor,
                            error: refButtonColorError,
                            cols: 3,
                            readonly: ref(true)
                        }
                    )
                    .addColorPicker(
                        refButtonColor,
                        {
                            size: "large",
                            block: true
                        }
                    )
                    .build()
            )
            .addContainer(
                buildContainer()
                    .setHorizontal()
                    .addInput(
                        {
                            en: "External Link",
                            de: "Verlinkung"
                        },
                        refButtonSourceUrl,
                        {
                            error: refButtonSourceUrlError
                        }
                    )
                    .addSelect(
                        {
                            en: "Allowed User Roles",
                            de: "Erlaubte Nutzerrollen"
                        },
                        refButtonPermissions,
                        refPermissionOptions,
                        {
                            multiple: true
                        }
                    )
                    .build()
            )
            .addTabs(
                buildTabs()
                    .setOptions({
                        fixed: true
                    })
                    .addMultipleTabs(Object.values(LANGUAGE).map(language => {
                        return {
                            title: Text.buildFromString(language.toUpperCase()).getDataObject(),
                            content: buildContainer()
                                .addInput(
                                    {
                                        en: `Title (${language.toUpperCase()})`,
                                        de: `Titel (${language.toUpperCase()})`
                                    },
                                    refsButtonTitle[language],
                                    {
                                        error: computed(() => refsButtonTitle[language].value === undefined || refsButtonTitle[language].value === "")
                                    }
                                )
                                .addTextEditor(
                                    {
                                        en: `Description (${language.toUpperCase()})`,
                                        de: `Beschreibung (${language.toUpperCase()})`
                                    },
                                    refsButtonDesc[language]
                                )
                                .addPreviewButton(
                                    computed(() => {
                                        const title = refsButtonTitle[language].value;
                                        return Text.buildFromString(title ? title : "").getDataObject()
                                    }),
                                    refButtonColor,
                                    refButtonIcon,
                                    computed(() => {
                                        const desc = refsButtonDesc[language].value;
                                        return desc ? Text.buildFromString(desc).getDataObject() : undefined
                                    }),
                                    refButtonSourceUrl,
                                    computed(() => Array.isArray(refButtonPermissions.value) && refButtonPermissions.value.length == 0 ? undefined : refButtonPermissions.value)
                                )
                                .build(),
                            options: {
                                prependIcon: computed(() => refsButtonTitle[language].value === undefined || refsButtonTitle[language].value === "" ? "mdi-pencil-circle" : "mdi-check-circle"),
                                error: refsButtonTitleError[language],
                                color: computed(() => refsButtonTitle[language].value === undefined || refsButtonTitle[language].value === "" ? "#f62e36" : "#80cc28"),
                                colorInactive: computed(() => refsButtonTitle[language].value === undefined || refsButtonTitle[language].value === "" ? "#f62e36" : "#80cc28")
                            }
                        }
                    }))
                    .build()
            )
            .build(),
        () => Object.values(refsButtonTitleError).reduce((prev, curr) => prev && !curr.value, true) &&
            !refButtonIconError.value && !refButtonColorError.value && !refButtonSourceUrlError.value
    );
    // STEP 2: Source
    const refGeoDataUrl = ref();
    if (isRequest(data?.sources[0]?.request)) {
        log.debug(`Set geo data url to '${data?.sources[0]?.request.url}'`);
        refGeoDataUrl.value = data?.sources[0]?.request.url;
    }
    const refGeoDataUrlLoadingState = ref(false);
    const refGeoDataRequestBody = ref();
    const refGeoDataRoot = ref();
    if (isRequest(data?.sources[0]?.request)) {
        log.debug(`Set data root to '${data?.sources[0].request.dataRoot ? data?.sources[0].request.dataRoot : ""}'`);
        refGeoDataRoot.value = data?.sources[0].request.dataRoot ? data?.sources[0].request.dataRoot : "";
    }
    const refGeoDataPreview = computed(() => refGeoDataRoot.value !== undefined && refGeoDataRequestBody.value !== undefined ? DataUtils.getValueByPath(refGeoDataRoot.value, refGeoDataRequestBody.value) : undefined);
    const refGeoDataUrlError = computed(() => !StringUtils.isURL(refGeoDataUrl.value));
    const refGeoDataPreviewError = computed(() => !(refGeoDataPreview.value !== undefined && Array.isArray(refGeoDataPreview.value) && refGeoDataPreview.value.every(gd => typeof gd === 'object')));
    const proxyGeoDataUrl = (url: string) => {
        if (StringUtils.isURL(url)) {
            refGeoDataUrlLoadingState.value = true;
            return useConnection().proxyRequest(url, { truncateData: true, truncateOptions: { onlyObjectArrays: true } })
                .then((data: any) => {
                    refGeoDataRequestBody.value = data
                    refGeoDataUrlLoadingState.value = false;
                })
                .catch(() => {
                    refGeoDataRequestBody.value = undefined;
                    refGeoDataUrlLoadingState.value = false;
                });
        } else {
            refGeoDataRequestBody.value = undefined;
        }
        return Promise.reject();
    }
    stepper.addStep(
        {
            en: "Source",
            de: "Quelle"
        },
        "mdi-link",
        buildContainer()
            .addHeader({
                en: "Source for Geodata",
                de: "Quelle der Geodaten"
            })
            .addDesc({
                en: "Provide the URL to the data source.",
                de: "Geben Sie die URL zur Daten-Quelle an."
            })
            .addInput(
                {
                    en: "URL",
                    de: "URL"
                },
                refGeoDataUrl,
                {
                    error: refGeoDataUrlError,
                    loading: refGeoDataUrlLoadingState,
                    onChange: () => {
                        proxyGeoDataUrl(refGeoDataUrl.value);
                    }
                }
            )
            .addDesc({
                en: "Select the attribute which contains the data array you want to use for generating the map points. The elements of the array should contain coordinates somewhere.",
                de: "Wählen sie ein Attribut aus, das eine Liste von Objekten enthält. Auf Basis der Objekte werden die Punkte auf der Karte generiert. Die Objekte in der Liste sollten an irgendeiner Stelle Geo-Koordinaten enthalten."
            })
            .addJsonArea(
                {
                    en: "Select data root",
                    de: "Datenursprung auswählen"
                },
                refGeoDataRequestBody,
                {
                    rows: 8,
                    onClick: (event: any) => {
                        refGeoDataRoot.value = event.path;
                    }
                }
            )
            .addDesc({
                en: "This preview shows what data array will be used as a basis for the map element locations (e.g. marker locations).",
                de: "Diese Vorschau zeigt welche Datenliste später für die Verortung der Kartenelement (z.B. Marker) verwendet wird."
            })
            .addJsonArea(
                {
                    en: "Data Preview",
                    de: "Datenvorschau"
                },
                refGeoDataPreview,
                {
                    rows: 8,
                    error: refGeoDataPreviewError
                }
            )
            .build(),
        () => !refGeoDataUrlError.value && !refGeoDataPreviewError.value
    )
    // STEP 3: Select coordinate fields
    const refCoordinatesLongitude = ref(data?.sources && data.sources.length > 0 && Array.isArray(data.sources[0].mapTemplate.coordinates) ? StringUtils.extractZmRef(data.sources[0].mapTemplate.coordinates[1] as string) : undefined);
    const refCoordinatesLatitude = ref(data?.sources && data.sources.length > 0 && Array.isArray(data.sources[0].mapTemplate.coordinates) ? StringUtils.extractZmRef(data.sources[0].mapTemplate.coordinates[0] as string) : undefined);
    const refsCoordinatesName = DataUtils.createLangRefs();
    if (data?.sources[0]?.mapTemplate?.name) {
        const name = data?.sources[0]?.mapTemplate?.name;
        if (isText(name)) {
            Object.values(LANGUAGE).forEach(language => {
                refsCoordinatesName[language].value = name[language];
            });
        } else {
            Object.values(LANGUAGE).forEach(language => {
                refsCoordinatesName[language].value = name;
            });
        }
    }
    const refsCoordinatesNamePreview = computed(() => Object.values(LANGUAGE).reduce((prev, language) => {
        return { ...prev, [language]: FetchWorkerUtils.replaceByMatrix(refsCoordinatesName[language].value, DataUtils.unproxy(refGeoDataPreview.value[0])) }
    }, {} as Record<LANGUAGE, string>))
    const refCoordinatesLongitudeError = computed(() => {
        if (refCoordinatesLongitude.value !== undefined && refGeoDataPreview.value !== undefined) {
            const value = DataUtils.getValueByPath(refCoordinatesLongitude.value + "", refGeoDataPreview.value[0]);
            return value === undefined || isNaN(Number(value));
        }
        return true;
    });
    const refCoordinatesLatitudeError = computed(() => {
        if (refCoordinatesLatitude.value !== undefined && refGeoDataPreview.value !== undefined) {
            const value = DataUtils.getValueByPath(refCoordinatesLatitude.value + "", refGeoDataPreview.value[0]);
            return value === undefined || isNaN(Number(value));
        }
        return true;
    });
    const refCoordinatesNameError = computed(() => {
        return Object.values(LANGUAGE).reduce((prev, language) => prev || (refsCoordinatesNamePreview.value[language] === undefined || refsCoordinatesNamePreview.value[language] === ""), false);
    })
    const refsCoordinatesNameError = Object.values(LANGUAGE).reduce((previous, language) => {
        return { ...previous, [language]: computed(() => refsCoordinatesNamePreview.value[language] === undefined || refsCoordinatesNamePreview.value[language] === "") }
    }, {} as Record<LANGUAGE, Ref<boolean>>)
    stepper.addStep(
        {
            en: "Coordinates",
            de: "Koordinaten"
        },
        "mdi-map-marker-circle",
        buildContainer()
            .addHeader({
                en: "Map Element",
                de: "Kartenelement"
            })
            .addDesc({
                en: "Choose the attributes containing the latitude and longitude. The value must be numeric or a string that can be converted to a number (e.g. 42.123 or \"42.123\").",
                de: "Wähle die Attribute aus, welche den Wert für den Breitengrad bzw. Längengrad enthalten. Der Wert muss als Nummer oder als Zeichenkette die in eine Nummer umwandelbar ist vorliegen (z.B. 42.123 oder \"42.123\")."
            })
            .addContainer(
                buildContainer()
                    .setHorizontal()
                    .addInput(
                        {
                            en: "Longitude",
                            de: "Längengrad"
                        },
                        refCoordinatesLongitude,
                        {
                            error: refCoordinatesLongitudeError,
                            cols: 5
                        }
                    )
                    .addJsonPicker(
                        computed(() => refGeoDataPreview.value !== undefined ? refGeoDataPreview.value[0] : undefined),
                        (event:any) => {
                            refCoordinatesLongitude.value = event.path
                        },
                        {
                            size: "large",
                            block: true,
                            closeOnContentClick: true
                        }
                    )
                    .build()
            )
            .addContainer(
                buildContainer()
                    .setHorizontal()
                    .addInput(
                        {
                            en: "Latitude",
                            de: "Breitengrad"
                        },
                        refCoordinatesLatitude,
                        {
                            error: refCoordinatesLatitudeError,
                            cols: 5
                        }
                    )
                    .addJsonPicker(
                        computed(() => refGeoDataPreview.value !== undefined ? refGeoDataPreview.value[0] : undefined),
                        (event:any) => { refCoordinatesLatitude.value = event.path },
                        {
                            size: "large",
                            block: true
                        }
                    )
                    .build()
            )
            .addTabs(
                buildTabs()
                    .setOptions({
                        hidden: computed(() => refFormattingSelectedType.value !== "TEXT"),
                        fixed: true
                    })
                    .addMultipleTabs(Object.values(LANGUAGE).map((language) => {
                        return {
                            title: Text.buildFromString(language.toUpperCase()).getDataObject(),
                            content: buildContainer()
                                .addContainer(
                                    buildContainer()
                                        .setHorizontal()
                                        .addInput(
                                            {
                                                en: `Marker Title (${language.toUpperCase()})`,
                                                de: `Marker Benennung (${language.toUpperCase()})`
                                            },
                                            refsCoordinatesName[language],
                                            {
                                                cols: 6,
                                                error: refsCoordinatesNameError[language]
                                            }
                                        )
                                        .addJsonPicker(
                                            computed(() => refGeoDataPreview.value !== undefined ? refGeoDataPreview.value[0] : undefined),
                                            (event:any) => {
                                                refsCoordinatesName[language].value = refsCoordinatesName[language].value ? refsCoordinatesName[language].value + StringUtils.buildZmRef(event.path) : StringUtils.buildZmRef(event.path) + "";
                                            },
                                            {
                                                size: "large",
                                                block: true
                                            }
                                        )
                                        .build()
                                )
                                .addInput(
                                    {
                                        en: `Preview (${language.toUpperCase()})`,
                                        de: `Vorschau (${language.toUpperCase()})`
                                    },
                                    computed(() => refGeoDataPreview.value ? FetchWorkerUtils.replaceByMatrix(refsCoordinatesName[language].value, DataUtils.unproxy(refGeoDataPreview.value[0])) : undefined),
                                    {
                                        readonly: ref(true),
                                        error: refsCoordinatesNameError[language]
                                    }
                                )
                                .build(),
                            options: {
                                prependIcon: computed(() => refsCoordinatesNameError[language].value ? "mdi-pencil-circle" : "mdi-check-circle"),
                                error: refsCoordinatesNameError[language],
                                color: computed(() => refsCoordinatesNameError[language].value ? "#f62e36" : "#80cc28"),
                                colorInactive: computed(() => refsCoordinatesNameError[language].value ? "#f62e36" : "#80cc28")
                            }
                        }
                    }))
                    .build()
            )
            .build(),
        () => !refCoordinatesLatitudeError.value && !refCoordinatesLongitudeError.value && !refCoordinatesNameError.value
    )
    // STEP 4:
    const refStylingIcon = ref(data?.sources[0]?.mapTemplate?.icon && typeof data?.sources[0]?.mapTemplate?.icon === "string" ? data?.sources[0]?.mapTemplate?.icon : refButtonIcon.value);
    const refStylingIconColor = ref(data?.sources[0]?.mapTemplate?.iconColor && typeof data?.sources[0]?.mapTemplate?.iconColor === "string" ? data?.sources[0]?.mapTemplate?.iconColor : null);
    const refStylingColor = ref(data?.sources[0]?.mapTemplate?.color && typeof data?.sources[0]?.mapTemplate?.color === "string" ? data?.sources[0]?.mapTemplate?.color : undefined);
    stepper.addStep(
        {
            en: "Styling",
            de: "Visualisierung"
        },
        "mdi-brush-variant",
        buildContainer()
            .addHeader({
                en: "Styling",
                de: "Visualisierung"
            })
            .addDesc({
                en: "Choose the colors and the icon for the markers.",
                de: "Wähle die Farben und das Symbol aus, die für die Marker verwendet werden sollen."
            })
            .addContainer(
                buildContainer()
                    .setHorizontal()
                    .addInput(
                        {
                            en: "Element Icon",
                            de: "Symbol des Elements"
                        },
                        refStylingIcon,
                        {
                            prependInnerIcon: refStylingIcon,
                            cols: 5,
                            readonly: ref(true)
                        }
                    )
                    .addIconPicker(
                        refStylingIcon,
                        {
                            size: "large",
                            block: true
                        }
                    )
                    .build()
            )
            .addContainer(
                buildContainer()
                    .setHorizontal()
                    .addInput(
                        {
                            en: "Icon Color",
                            de: "Symbolfarbe"
                        },
                        computed(() => refStylingIconColor.value === null ? Text.asString({ en: "Automatic", de: "Automatisch" }) : refStylingIconColor.value),
                        {
                            prependInnerIcon: computed(() => refStylingIconColor.value !== null ? "mdi-circle" : "mdi-circle-half-full"),
                            innerIconColor: computed(() => refStylingIconColor.value !== null ? refStylingIconColor.value : useStore().get(SID.THEME, THEME.LIGHT) == THEME.LIGHT ? "#000" : "#FFF"),
                            cols: 5,
                            readonly: ref(true)
                        }
                    )
                    .addColorPicker(
                        refStylingIconColor,
                        {
                            size: "large",
                            block: true,
                            autoColor: null
                        }
                    )
                    .build()
            )
            .addContainer(
                buildContainer()
                    .setHorizontal()
                    .addInput(
                        {
                            en: "Element Color",
                            de: "Elementfarbe"
                        },
                        refStylingColor,
                        {
                            prependInnerIcon: ref("mdi-circle"),
                            innerIconColor: refStylingColor,
                            cols: 5,
                            readonly: ref(true)
                        }
                    )
                    .addColorPicker(
                        refStylingColor,
                        {
                            size: "large",
                            block: true
                        }
                    )
                    .build()
            )
            .addPreviewMarker(
                refStylingIcon,
                refStylingIconColor,
                refStylingColor
            )
            .build(),
        () => true,
        {
            onMount: () => {
                if (refStylingIcon.value === undefined) {
                    refStylingIcon.value = refButtonIcon.value;
                }
                if (refStylingColor.value === undefined) {
                    refStylingColor.value = refButtonColor.value;
                }
            }
        }
    )
    // STEP 5: Context Source
    const refContextUseGeoDataAsSource = ref(
        isRequest(data?.sources[0]?.context?.request) && isRequest(data?.sources[0]?.request) ?
            data?.sources[0]?.context?.request?.url === data?.sources[0]?.request.url :
            undefined
    );
    const refContextRequestUrl = ref(isRequest(data?.sources[0]?.context?.request) ? data?.sources[0]?.context?.request?.url : undefined);

    const refContextPreviewFetched = ref();
    const refContextPreview = computed(() => refContextUseGeoDataAsSource.value === true ?
        Array.isArray(refGeoDataPreview.value) ? refGeoDataPreview.value[0] : undefined : refContextPreviewFetched.value);
    const refContextPreviewLoading = ref();
    const refContextPathToId = ref(refContextUseGeoDataAsSource.value && isRequest(data?.sources[0]?.context?.request) && data?.sources[0]?.context?.request?.dataRoot ? StringUtils.extractZmRef(data?.sources[0]?.context?.request?.dataRoot) : undefined);
    const refContextIdPreview = computed(() => refContextUseGeoDataAsSource.value && refContextPathToId.value !== undefined && refContextPreview.value !== undefined ? DataUtils.getValueByPath(refContextPathToId.value, refContextPreview.value) : undefined);
    const refContextDataWasFetched = ref(false);

    const refContextPathToIdError = computed(() => {
        return !(refContextPathToId.value && refContextPreview.value &&
            DataUtils.getValueByPath(refContextPathToId.value, refContextPreview.value) !== undefined && refContextPathToId.value !== "")
    });
    const refContextRequestError = computed(() => {
        return !StringUtils.isURL(refContextRequestUrl.value) || !refContextDataWasFetched.value
    })
    const fetchContextPreviewByUrl = (url: string) => {
        if (StringUtils.isURL(url)) {
            refContextPreviewLoading.value = true
            useConnection().proxyRequest(url, {
                truncateData: true, truncateOptions: { onlyObjectArrays: true }
            }).then((data: any) => {
                refContextDataWasFetched.value = true;
                refContextPreviewFetched.value = data;
                refContextPreviewLoading.value = false;
            }).catch((error: any) => {
                refContextPreviewLoading.value = false;
                refContextDataWasFetched.value = false;
                useSnackbar().error(Text.asString({
                    en: "An error occurred during data fetching!",
                    de: "Bei der Datenübertragung ist ein Fehler aufgetreten!"
                }))
            });
        }
    }
    if (data?.sources && data.sources.length > 0 && isRequest(data?.sources[0].request)) {
        refGeoDataUrl.value = data.sources[0].request.url;
        proxyGeoDataUrl(refGeoDataUrl.value).then(() => {
            if (refContextRequestUrl.value !== undefined) {
                const url = FetchWorkerUtils.replaceByMatrix(refContextRequestUrl.value, DataUtils.unproxy(DataUtils.getValueByPath(refGeoDataRoot.value ? refGeoDataRoot.value : "", refGeoDataRequestBody.value)));
                fetchContextPreviewByUrl(url);
            }
        })
    }

    stepper.addStep(
        {
            en: "Context",
            de: "Kontext"
        },
        "mdi-information",
        buildContainer()
            .addHeader({
                en: "Context",
                de: "Kontext"
            })
            .addDesc({
                en: "Specify a URL via which the context for the individual displayed map elements is to be obtained. This usually requires IDs or similar, which must be specified in the URL. Placeholders can be inserted for such variable values in the URL. You may use the button to do insert such wildcards.",
                de: "Gib eine URL an über die der Kontext für die einzelnen, dargestellten Kartenelemente bezogen werden soll. Üblicherweise sind dafür IDs oder dergleichen notwendig, die in der URL angegeben werden müssen. Für solche veränderlichen Werte in der URL können Platzhalter eingefügt werden. Benutze dafür die Schaltfläche."
            })
            .addContainer(
                buildContainer()
                    .setHorizontal()
                    .addTextArea(
                        {
                            en: "Context URL",
                            de: "Kontext URL"
                        },
                        refContextRequestUrl,
                        {
                            disabled: refContextUseGeoDataAsSource,
                            error: refContextRequestError,
                            cols: 5,
                            rows: 2,
                            onChange: () => {
                                if (Array.isArray(refGeoDataPreview.value)) {
                                    const url = FetchWorkerUtils.replaceByMatrix(refContextRequestUrl.value, DataUtils.unproxy(refGeoDataPreview.value[0]));
                                    fetchContextPreviewByUrl(url);
                                }
                            }
                        }
                    )
                    .addJsonPicker(
                        computed(() => Array.isArray(refGeoDataPreview.value) ? refGeoDataPreview.value[0] : undefined),
                        (event:any) => {
                            refContextRequestUrl.value = refContextRequestUrl.value ? refContextRequestUrl.value + StringUtils.buildZmRef(event.path) : StringUtils.buildZmRef(event.path) + "";
                            if (Array.isArray(refGeoDataPreview.value)) {
                                const url = FetchWorkerUtils.replaceByMatrix(refContextRequestUrl.value, DataUtils.unproxy(refGeoDataPreview.value[0]));
                                fetchContextPreviewByUrl(url);
                            }
                        },
                        {
                            disabled: refContextUseGeoDataAsSource,
                            size: "large",
                            block: true
                        }
                    )
                    .build()
            )
            .addDesc({
                en: "If the data in the list of geodata is sufficient, it can be used as a source for the context instead.",
                de: "Wenn die Daten in der Liste der Geodaten ausreichend sind, können diese genutzt werden um als Quelle für den Kontext zu dienen."
            })
            .addCheckbox(
                {
                    en: "Use list of geo data as a source",
                    de: "Geodatenliste als Quelle nutzen"
                },
                refContextUseGeoDataAsSource,
                {
                    onChange: () => {
                        if (refContextUseGeoDataAsSource.value !== true && StringUtils.isURL(refContextRequestUrl.value)) {
                            fetchContextPreviewByUrl(DataUtils.containsWildcardOrConditional(refContextRequestUrl.value) ?
                                FetchWorkerUtils.replaceByMatrix(refContextRequestUrl.value, DataUtils.unproxy(refGeoDataPreview.value[0])) : refContextRequestUrl.value);
                        } else {
                            refContextPreviewFetched.value = undefined;
                        }
                    }
                }
            )
            .addDesc({
                en: "In order to identify the context data in the geo list, choose path to a unique identifier (ID) in from the object:",
                de: "Um die Kontextobjekte in der Geodatenliste zu identifizieren, gib einen Pfad zu einem Attribut an, dass einen einzigartigen Wert (ID) beinhaltet:"
            })
            .addContainer(
                buildContainer()
                    .setHorizontal()
                    .addInput(
                        {
                            en: "Path to ID",
                            de: "Pfad zur ID"
                        },
                        refContextPathToId,
                        {
                            disabled: computed(() => !refContextUseGeoDataAsSource.value),
                            error: refContextPathToIdError,
                            cols: 3,
                            readonly: ref(true)
                        }
                    )
                    .addInput(
                        {
                            en: "Example ID Value",
                            de: "Beispiel ID-Wert"
                        },
                        refContextIdPreview,
                        {
                            disabled: computed(() => !refContextUseGeoDataAsSource.value),
                            error: refContextPathToIdError,
                            cols: 2,
                            readonly: ref(true)
                        }
                    )
                    .addJsonPicker(
                        refContextPreview,
                        (event: any) => {
                            refContextPathToId.value = event.path;
                        },
                        {
                            size: "large",
                            disabled: computed(() => !refContextUseGeoDataAsSource.value)
                        }
                    )
                    .build()
            )
            .addJsonArea(
                {
                    en: "Context Preview",
                    de: "Kontext Vorschau"
                },
                refContextPreview,
                {
                    loading: refContextPreviewLoading,
                    rows: 8
                }
            )
            .build(),
        () => {
            return refContextUseGeoDataAsSource.value ?
                !refContextPathToIdError.value :
                !refContextRequestError.value
        }
    )
    // STEP 6: Formatting
    const refFormattingTypes = ref([
        {
            title: Text.asString({ en: "Text", de: "Text" }),
            value: CONTEXT_TYPES.TEXT
        },
        {
            title: Text.asString({ en: "Table", de: "Tabelle" }),
            value: CONTEXT_TYPES.TABLE
        },
    ]);
    const refFormattingSelectedType = ref(data?.sources[0]?.context?.formatting?.type ? data.sources[0].context.formatting.type : CONTEXT_TYPES.TEXT);
    const refsFormattingTextTemplates = DataUtils.createLangRefs();
    const formattingValue = data?.sources[0]?.context?.formatting?.value;
    if (
        formattingValue && isText(formattingValue)
    ) {
        Object.values(LANGUAGE).forEach(language => {
            refsFormattingTextTemplates[language].value = formattingValue[language];
        });
    }
    const refsFormattingTextTemplatesError = Object.values(LANGUAGE).reduce((prev, language) => {
        return { ...prev, [language]: computed(() => refsFormattingTextTemplates[language].value === undefined || refsFormattingTextTemplates[language].value === "") }
    }, {} as Record<LANGUAGE, Ref<boolean>>)
    stepper.addStep(
        {
            en: "Formatting",
            de: "Formatierung"
        },
        "mdi-help",
        buildContainer()
            .addHeader({
                en: "Context Formatting",
                de: "Kontext Formatierung"
            })
            .addDesc({
                en: "Choose how to format the context that will be displayed when the button is clicked or hovered upon.",
                de: "Wähle die Formatierung aus, die für den Kontext der Daten verwendet werden soll. Der Kontext wird angezeigt wenn auf einen Datenpunkt geklickt wird oder die Maus darüber fährt."
            })
            .addSelect(
                {
                    en: "Formatting Type",
                    de: "Formatierungstyp"
                },
                refFormattingSelectedType,
                refFormattingTypes,
            )
            .addContainer(
                buildContainer()
                    .setOptions({
                        hidden: computed(() => refFormattingSelectedType.value !== "TABLE")
                    })
                    .addDesc({
                        en: "This formatting option is currently not available!",
                        de: "Diese Formattierungsvariante ist im Moment nicht verfügbar!"
                    })
                    .build()
            )
            .addTabs(
                buildTabs()
                    .setOptions({
                        hidden: computed(() => refFormattingSelectedType.value !== "TEXT"),
                        fixed: true
                    })
                    .addMultipleTabs(Object.values(LANGUAGE).map((language) => {
                        return {
                            title: Text.buildFromString(language.toUpperCase()).getDataObject(),
                            content: buildContainer()
                                .addTextEditor(
                                    {
                                        en: `Context (${language.toUpperCase()})`,
                                        de: `Kontext (${language.toUpperCase()})`
                                    },
                                    refsFormattingTextTemplates[language],
                                    {
                                        pickableData: refContextPreview
                                    }
                                )
                                .addPreviewContext(
                                    computed(() => {
                                        const title = refsButtonTitle[language].value;
                                        if (title) {
                                            return Text.buildFromString(title).getDataObject()
                                        }
                                        return undefined
                                    }),
                                    computed(() => refContextPreview.value ? Text.buildFromString(FetchWorkerUtils.replaceByMatrix(refsFormattingTextTemplates[language].value, DataUtils.unproxy(refContextPreview.value))).getDataObject() : undefined),
                                    refStylingIcon,
                                    refStylingColor
                                )
                                .build(),
                            options: {
                                prependIcon: computed(() => refsFormattingTextTemplates[language].value === undefined || refsFormattingTextTemplates[language].value === "" ? "mdi-pencil-circle" : "mdi-check-circle"),
                                error: refsFormattingTextTemplatesError[language],
                                color: computed(() => refsFormattingTextTemplates[language].value === undefined || refsFormattingTextTemplates[language].value === "" ? "#f62e36" : "#80cc28"),
                                colorInactive: computed(() => refsFormattingTextTemplates[language].value === undefined || refsFormattingTextTemplates[language].value === "" ? "#f62e36" : "#80cc28")
                            }
                        }
                    }))
                    .build()
            )
            .build(),
        () => refFormattingSelectedType.value === CONTEXT_TYPES.TEXT ? Object.values(LANGUAGE).reduce((prev, language) => prev && !refsFormattingTextTemplatesError[language].value, true) : false
    )
    const refAddButtonLoadingState = ref(false);
    stepper
        .setOptions({
            submitLoading: refAddButtonLoadingState,
            submitTitle: options?.submitText ? options.submitText : {
                en: "Add Data",
                de: "Hinzufügen"
            }
        })
        .setSubmit(() => {
            refAddButtonLoadingState.value = true;
            try {
                // build name text
                const name: any = {};
                Object.values(LANGUAGE).forEach(language => {
                    name[language] = refsButtonTitle[language].value;
                });
                // build formatting
                const formatting: any = {};
                if (refFormattingSelectedType.value === CONTEXT_TYPES.TEXT) {
                    formatting["type"] = CONTEXT_TYPES.TEXT;
                    formatting["value"] = {};
                    Object.values(LANGUAGE).forEach(language => {
                        formatting.value[language] = refsFormattingTextTemplates[language].value;
                    });
                }
                if (refFormattingSelectedType.value === CONTEXT_TYPES.TABLE) {
                    formatting["type"] = CONTEXT_TYPES.TABLE;
                    formatting["value"] = {
                        columns: [],
                        rows: []
                    };
                    // TODO: add table formatting
                    // formatting.value.columns = refsContextFormattingTableColumns.value;
                    // formatting.value.rows = refsContextFormattingTableRows.value;
                }

                let contextRoot = "";
                if (refContextUseGeoDataAsSource.value && refContextPathToId.value) {
                    contextRoot = `${refGeoDataRoot.value}[${refContextPathToId.value}?${StringUtils.buildZmRef(refContextPathToId.value)}]`;
                }

                const createdSource: any = {
                    id: data?.sources[0]?.id ? data?.sources[0]?.id : crypto.randomUUID(),
                    request: {
                        id: isFetchable(data?.sources[0]?.request) ? data?.sources[0]?.request.id : crypto.randomUUID(),
                        type: "HTTP",
                        url: refGeoDataUrl.value,
                        dataRoot: refGeoDataRoot.value,
                        permission: Array.isArray(refButtonPermissions.value) && refButtonPermissions.value.length > 0 ? refButtonPermissions.value : undefined
                    },
                    mapTemplate: {
                        type: MAP_TEMPLATE.MARKER,
                        name: Object.values(LANGUAGE).reduce((prev, language) => {
                            return { ...prev, [language]: refsCoordinatesName[language].value }
                        }, {}),
                        coordinates: [
                            typeof refCoordinatesLatitude.value === "string" ? StringUtils.buildZmRef(refCoordinatesLatitude.value) : refCoordinatesLatitude.value,
                            typeof refCoordinatesLongitude.value === "string" ? StringUtils.buildZmRef(refCoordinatesLongitude.value) : refCoordinatesLongitude.value
                        ],
                        icon: refStylingIcon.value,
                        color: refStylingColor.value,
                        iconColor: refStylingIconColor.value !== null ? refStylingIconColor.value : undefined
                    },
                    context: {
                        request: {
                            id: isFetchable(data?.sources[0]?.context?.request) ? data?.sources[0]?.context?.request.id : crypto.randomUUID(),
                            type: "HTTP",
                            url: refContextUseGeoDataAsSource.value ? refGeoDataUrl.value : refContextRequestUrl.value,
                            dataRoot: contextRoot,
                            permission: Array.isArray(refButtonPermissions.value) && refButtonPermissions.value.length > 0 ? refButtonPermissions.value : undefined
                        },
                        formatting: formatting
                    }
                };

                const content: i_entryDto = {
                    id: data?.id ? data.id : crypto.randomUUID(),
                    name: name as i_text,
                    icon: refButtonIcon.value as string,
                    color: refButtonColor.value as string,
                    sources: [
                        createdSource
                    ] as i_sourceDto[]
                };

                if (Object.values(refsButtonDesc).every(desc => desc.value !== undefined && desc.value !== "")) {
                    DataUtils.setValueByPath(content, "info.description", Object.values(LANGUAGE).reduce((prev, language) => {
                        return {
                            ...prev,
                            [language]: refsButtonDesc[language].value
                        }
                    }, {}));
                }
                if (StringUtils.isURL(refButtonSourceUrl.value)) {
                    DataUtils.setValueByPath(content, "info.link", refButtonSourceUrl.value);
                }

                if (log.willDebug()) {
                    log.debug(JSON.stringify(content));
                }

                submitCallback(content, refAddButtonLoadingState);
            } catch (e: any) {
                refAddButtonLoadingState.value = false;
                log.error(e.message);
                useSnackbar().error(new Text({
                    en: "An error occurred while processing the data!",
                    de: "Ein Fehler ist bei der Verarbeitung der Daten aufgetreten!"
                }).get())
            }
        })
    const dialog = buildDialog()
        .setTitle(options?.title ? options.title : {
            en: "Create Data Entry",
            de: "Daten hinzufügen"
        })
        .setWidth(600)
        .setContent(
            buildContainer()
                .addStepper(stepper.build())
                .build()
        )
        .build()
    useDialog().set(dialog);
    useDialog().open();
}
