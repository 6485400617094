import {createVuetify, useDisplay} from 'vuetify'
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import {aliases, mdi} from "vuetify/iconsets/mdi";
import {darkTheme, lightTheme} from "@/themes";

export const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        }
    },
    theme: {
        defaultTheme: 'light',
        themes: {
            dark: darkTheme,
            light: lightTheme
        }
    },
})

export const isMobile = () => {
    return useDisplay().xs.value;
};
