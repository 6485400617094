export enum CONTEXT_TYPES {
    TEXT = "TEXT",
    TABLE = "TABLE"
}

export enum SORT_TYPES {
    UP = "UP",
    DOWN = "DOWN"
}

export enum MAP_TEMPLATE {
    POLYLINE = "POLYLINE",
    MARKER = "MARKER"
}

export enum GEOJSON_TYPES {
    POINT = "POINT",
    LINESTRING = "LINESTRING",
    POLYGON = "POLYGON"
}

export enum PANEL_TYPES {
    ROUTER = "ROUTER",
    DATA = "DATA"
}

export enum MAPPING_TYPE {
    ADD = "ADD",
    MULTIPLY = "MULTIPLY",
    SUBTRACT = "SUBTRACT",
    APPEND = "APPEND",
    REMOVE = "REMOVE",
    CUSTOM = "CUSTOM"
}

export enum USER_ROLES {
    ADMIN = "admin",
    CLERK = "clerk",
    PRIVILEGED_USER = "privileged",
    USER = "user"
}
