import {MDI, MOT} from "@/types/enums/frontend.enums";
import {i_text} from "@/types/interfaces/structure.interfaces";

export const motColors: Record<MOT, string> = {
    [MOT.TRAM]: "#f68712",
    [MOT.RAIL]: "#b347d5",
    [MOT.BUS]: "#d51961",
    [MOT.CAR]: "#f62e36",
    [MOT.BIKE]: "#80cc28",
    [MOT.WALK]: "#50accb",
    [MOT.SCOOTER]: "brown",
    [MOT.UNKNOWN]: "black"
}

export const motIcons: Record<MOT, MDI> = {
    [MOT.TRAM]: MDI.TRAM,
    [MOT.RAIL]: MDI.TRAIN,
    [MOT.BUS]: MDI.BUS,
    [MOT.CAR]: MDI.CAR,
    [MOT.BIKE]: MDI.BICYCLE,
    [MOT.WALK]: MDI.WALK,
    [MOT.SCOOTER]: MDI.SCOOTER,
    [MOT.UNKNOWN]: MDI.HELP
}

export const motName: Record<MOT, i_text> = {
    [MOT.TRAM]: {
        en: "Tram",
        de: "Straßenbahn"
    },
    [MOT.RAIL]: {
        en: "Rail",
        de: "Bahn"
    },
    [MOT.BUS]: {
        en: "Bus",
        de: "Bus"
    },
    [MOT.CAR]: {
        en: "Car",
        de: "Pkw"
    },
    [MOT.BIKE]: {
        en: "Bicycle",
        de: "Fahrrad"
    },
    [MOT.WALK]: {
        en: "Walk",
        de: "Laufen"
    },
    [MOT.SCOOTER]: {
        en: "Scooter",
        de: "Roller"
    },
    [MOT.UNKNOWN]: {
        en: "Unknown",
        de: "Unbekannt"
    }
}
