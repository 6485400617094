import {LANGUAGE, SID} from "@/types/enums/frontend.enums";
import {isText} from "@/types/typeguards/general.guards";
import {i_text} from "@/types/interfaces/structure.interfaces";
import {useStore} from "@/services/store.service";

export class Text {
    private textObj:i_text;

    constructor(text:i_text) {
        if (isText(text)) {
            this.textObj = text;
        } else {
            this.textObj = {
                en: "invalid text",
                de: "ungültiger Text"
            }
        }
    }

    public get() {
        return this.textObj[useStore().get(SID.LANGUAGE, LANGUAGE.GERMAN)];
    }

    public set(text:i_text) {
        this.textObj = text;
    }

    public getDataObject(): i_text {
        return this.textObj;
    }

    public static buildFromString(str: string) {
        return new Text({
            en: str,
            de: str,
        })
    }

    public static buildUnknown() {
        return new Text({
            en: "unknown",
            de: "unbekannt",
        })
    }

    public static asString(text: i_text) {
        return text[useStore().get(SID.LANGUAGE, LANGUAGE.GERMAN)];
    }

    public static asText(text: i_text) {
        return new Text(text);
    }
}
