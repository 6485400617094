import {i_container, i_tab_options, i_tabs, i_tabs_options} from "@/types/interfaces/dialog.interfaces";
import {ITEM} from "@/types/enums/dialog.enums";
import {Ref, ref} from "vue";
import {i_text} from "@/types/interfaces/structure.interfaces";

class TabsBuilder {
    private tabs: i_tabs;

    constructor() {
        this.tabs = {
            type: ITEM.TABS,
            value: {
                currentTab: ref(0),
                tabs: []
            }
        }
    }

    public addTab(title: i_text, content: i_container, options?: i_tab_options) {
        this.tabs.value.tabs.push({
            name: title,
            content: content,
            options: options
        });
        return this;
    }

    public addMultipleTabs(tabs: {title: i_text, content: i_container, options?: i_tab_options}[]) {
        tabs.forEach(tab => {
            this.tabs.value.tabs.push({
                name: tab.title,
                content: tab.content,
                options: tab.options
            });
        });
        return this;
    }

    public setOptions(options: i_tabs_options) {
        this.tabs.value.options = options;
        return this;
    }

    public setTabsRef(tabRef: Ref<number>) {
        this.tabs.value.currentTab = tabRef;
        return this;
    }

    public build() {
        return this.tabs;
    }
}

export const buildTabs = () => {
    return new TabsBuilder();
}
