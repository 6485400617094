import axios from "axios";
import {Base} from "@/Base";
import {i_position, i_poi} from "@/types/interfaces/frontend.interfaces";
import {DataUtils} from "@/utils/data.utils";
import {useStore} from "@/services/store.service";
import {SID} from "@/types/enums/frontend.enums";
import {ZWICKAU_CENTRE} from "@/constants";
import {Text} from "@/utils/text";

class GeocoderService extends Base {
    private readonly nominatimBaseUrl: string;
    constructor() {
        super(GeocoderService.name);
        this.nominatimBaseUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/nominatim`;
    }

    public getByName(name: string) {
        return this.nominatimGetByName(name);
    }

    public getByPosition(position: i_position) {
        return this.nominatimGetByPosition(position);
    }

    public buildNomination(obj: any) {
        if (typeof obj === "object" && "address" in obj) {
            const categoryItem = obj.address[obj.category] ? `${obj.address[obj.category]}, ` : "";
            const road = obj.address.road ? obj.address.road : "";
            const houseNumber = obj.address.house_number ? ` ${obj.address.house_number}` : "";
            const roadAddress = road != "" || houseNumber != "" ? `${road}${houseNumber}, ` : "";
            const postcode = obj.address.postcode ? `${obj.address.postcode} ` : ""
            const town = obj.address.city ? obj.address.city :
                obj.address.town ? obj.address.town :
                    obj.address.village ? obj.address.village :
                        obj.address.muncipality ? obj.address.muncipality : "";
            return `${categoryItem}${roadAddress}${postcode}${town}`;
        }
        return obj.display_name;
    }

    private nominatimGetByName(name: string): Promise<i_poi[] | undefined> {
        const requestUrl = new URL(this.nominatimBaseUrl);
        requestUrl.pathname = requestUrl.pathname + "/search";
        requestUrl.searchParams.append("q", name);
        requestUrl.searchParams.append("addressdetails", "1");
        requestUrl.searchParams.append("limit", "20");
        requestUrl.searchParams.append("viewbox", "16.49,51.68,10.52,50.25");
        requestUrl.searchParams.append("bounded", "1");
        this.log.debug(requestUrl.toString());
        return axios.get(requestUrl.toString())//this.nominatimBaseUrl + "search?q=" + name)
            .then(response => {
                if (response.data.length > 0) {
                    const seenNames  = new Set<string>();
                    // map to i_poi
                    return response.data.map((e:any) => { return {
                        name: this.buildNomination(e),
                        location: {
                            lat: Number(e.lat),
                            lng: Number(e.lon)
                        }
                    } as { name: string, location: i_position } })
                        // proximity sort to current location
                        .sort((a:{ name: string, location: i_position }, b:{ name: string, location: i_position }) =>
                            DataUtils.haversineDistance(a.location, useStore().get(SID.CURRENT_POSITION, ZWICKAU_CENTRE)) - DataUtils.haversineDistance(b.location, useStore().get(SID.CURRENT_POSITION, ZWICKAU_CENTRE))
                        )
                        // remove duplicate names
                        .filter((obj: any) => {
                            if (seenNames.has(obj.name)) {
                                return false;
                            } else {
                                seenNames.add(obj.name);
                                return true;
                            }
                        })
                        .map((obj: any) => {
                            return {
                                name: Text.buildFromString(obj.name).getDataObject(),
                                location: {
                                    lat: obj.location.lat,
                                    lng: obj.location.lng
                                }
                            } as i_poi
                        })
                } else {
                    return undefined
                }
            })
            .catch(error => {
                this.log.error(error)
                return undefined;
            })
    }

    public buildByPositionUrl(position: i_position) {
        const requestUrl = new URL(this.nominatimBaseUrl);
        requestUrl.pathname = requestUrl.pathname + "/reverse";
        requestUrl.searchParams.append("format", "json");
        requestUrl.searchParams.append("lat", position.lat.toString());
        requestUrl.searchParams.append("lon", position.lng.toString());
        requestUrl.searchParams.append("addressdetails", "1");
        return requestUrl;
    }

    private nominatimGetByPosition(position: i_position): Promise<i_poi | undefined> {
        const requestUrl = this.buildByPositionUrl(position);
        this.log.debug(requestUrl.toString());
        return axios.get(requestUrl.toString())
            .then(response => {
                const data = response.data;
                if (data !== undefined && data.lat !== undefined && data.lon !== undefined) {
                    return {
                        name: this.buildNomination(data),
                        location: {
                            lat: Number(data.lat),
                            lng: Number(data.lon)
                        }
                    } as i_poi
                } else {
                    this.log.warn("Unable to find matching poi for location " + position.lat + ", " + position.lng);
                    return undefined;
                }
            })
            .catch(error => {
                this.log.error(error)
                return undefined;
            })
    }
}

const geocoderService = new GeocoderService();

export const useGeocoder = (): GeocoderService => {
    return geocoderService;
}
