import {i_text} from "@/types/interfaces/structure.interfaces";
import {i_position} from "@/types/interfaces/frontend.interfaces";
import {_ICONS} from "@/assets/icons";

export const MDI_ICONS = _ICONS.map(i => { return { title: i.split("-").map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(" "), value: `mdi-${i}`} });

export const BASE_URL = process.env.BASE_URL;
export const UNKNOWN_TEXT: i_text = { en: "unknown", de: "unbekannt" };
export const EMPTY_TEXT: i_text = { en: "", de: "" };

export const ZWICKAU_CENTRE = {
    lat: 50.72,
    lng: 12.49
} as i_position;
