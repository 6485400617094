import {Base} from "@/Base";

class PollingService extends Base {
    private activePolls: Record<string, number | undefined> = {};

    constructor() {
        super(PollingService.name);
    }

    public addPoll(id: string, callback: Function, rate?: number) {
        this.log.debug(`Add poll for id '${id}'`);
        if (this.isPolling(id)) {
            this.stopPoll(id);
            this.log.warn(`A poll for id '${id}' is active already, it will be terminated before running the new poll!`);
        }
        this.activePolls[id] = setInterval(callback, rate ? rate : 5000);
    }

    public stopPoll(id: string) {
        this.log.debug(`Stop poll of id '${id}'`);
        if (this.activePolls[id]) {
            clearInterval(this.activePolls[id]);
            this.activePolls[id] = undefined;
        } else {
            this.log.warn(`No active poll for id '${id}' was found!`);
        }
    }

    public isPolling(id: string) {
        return this.activePolls[id] !== undefined;
    }
}

const pollingService: PollingService = new PollingService();

export const usePolling = (): PollingService => {
    return pollingService;
}
